import React from 'react';

import { COUNTER } from 'utils/constants';

import bg1 from '../../assets/images/background/bg1.jpg';

import Count from './counter-sensor';

const Counter = () => {
    return (
        <>
            <div
                className="section-area section-sp1 bg-fix ovbl-dark text-white"
                style={{ backgroundImage: `url(${bg1})` }}
            >
                <div className="container-fluid">
                    <div className="row">
                        {COUNTER.map((data, index: number) => {
                            return (
                                <div key={index} className="col-lg-2 col-md-6 col-sm-6 col-6 m-b30">
                                    <div className="counter-style-1">
                                        <div className="text-white">
                                            <Count counter={data.value} />
                                            <span>+</span>
                                        </div>
                                        <span className="counter-text">{data.label}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Counter;

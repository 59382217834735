import React, { useState, useEffect } from 'react';

import Masonry from 'react-masonry-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { storeTypes, eventsTypes } from 'types';
import { convertDate, convertTime, getDayMode } from 'utils/helpers';
import EventPlaceHolder from '../assets/images/courses/pic10.jpg';
import { config } from '../config';

//Upcoming,Expired
const FilterList = ({ dataFilter, defaultTag, activeFilter }: any) => {
    return (
        <li aria-hidden className={`${activeFilter ? 'btn active' : 'btn'}`} onClick={() => defaultTag(dataFilter)}>
            <Link to={'#'}>{dataFilter}</Link>
        </li>
    );
};

const EventsContent = () => {
    const [selectedTag, setSelectedTag] = useState('');
    const [filteredImages, setFilterdImages] = useState<any>([]);

    const [formattedContent, setFormattedContent] = useState<eventsTypes.IEventData[]>([]);

    const { events } = useSelector((state: storeTypes.IStore) => state);
    const { data: eventsData, isLoading } = events;

    useEffect(() => {
        selectedTag === 'All'
            ? setFilterdImages(formattedContent)
            : setFilterdImages(
                  formattedContent.filter((image: eventsTypes.IEventData) =>
                      image.tag.find((element: any) => element === selectedTag)
                  )
              );
    }, [selectedTag, formattedContent]);

    useEffect(() => {
        if (!isLoading) {
            console.log('eventsData', eventsData);
            setFormattedContent([]);
            const formatData: any = [];
            eventsData.length &&
                eventsData.forEach((data: eventsTypes.IEventData) => {
                    const dayMode = getDayMode(data?.start_date);
                    const pushData = {
                        ...data,
                        tag: [dayMode],
                    };
                    formatData.push(pushData);
                });
            setSelectedTag('All');
            setFormattedContent(formatData);
        }
    }, [eventsData, isLoading]);

    return (
        <>
            <div className="feature-filters clearfix center m-b40">
                <ul className="filters" data-toggle="buttons">
                    <FilterList dataFilter="All" defaultTag={setSelectedTag} activeFilter={selectedTag === 'All'} />
                    {/*<FilterList
                        dataFilter="Happening"
                        defaultTag={setSelectedTag}
                        activeFilter={selectedTag === 'Happening' ? true : false}
                    />*/}
                    <FilterList
                        dataFilter="Upcoming"
                        defaultTag={setSelectedTag}
                        activeFilter={selectedTag === 'Upcoming'}
                    />
                    <FilterList
                        dataFilter="Expired"
                        defaultTag={setSelectedTag}
                        activeFilter={selectedTag === 'Expired'}
                    />
                </ul>
            </div>

            <Masonry className="ttr-gallery-listing magnific-image row">
                {filteredImages.map((item: eventsTypes.IEventData, index: number) => {
                    const eventImage = item.file_path ? `${config.imgBaseURL}/${item.file_path}` : null;
                    return (
                        <div className="action-card col-lg-6 col-md-6 col-sm-12" key={index}>
                            <a href={`/events-details/${item.id}`}>
                                <div className="event-bx m-b30">
                                    <div className="action-box">
                                        <img src={eventImage ? eventImage : EventPlaceHolder} alt="" />
                                        <div className="event-time">
                                            <div className="event-date">{convertDate(item?.start_date, 'DD')}</div>
                                            <div className="event-month">{convertDate(item?.start_date, 'MMMM')}</div>
                                            <div className="event-month">{item?.type}</div>
                                        </div>
                                    </div>

                                    <div className="info-bx">
                                        <div className="event-info">
                                            {item?.tag[0] !== 'Expired' ? (
                                                <ul className="media-post">
                                                    <li>
                                                        <Link to={`/events-details/${item.id}`}>
                                                            <i className="fa fa-calendar-o"></i>{' '}
                                                            {convertDate(item?.start_date, 'MMM-DD-YYYY')}
                                                            {' to '}
                                                            {convertDate(item?.end_date, 'MMM-DD-YYYY')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={`/events-details/${item.id}`}>
                                                            <i className="fa fa-clock-o"></i>{' '}
                                                            {convertTime(item?.start_time, 'hh:mm A')}{' '}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={`/events-details/${item.id}`}>
                                                            <i className="fa fa-user-o"></i> {item?.who_can_attend}
                                                        </Link>
                                                    </li>
                                                    {/*<li>
                                            <Link to={`/events-details/${item.id}`}>
                                                <i className="fa fa-map-marker"></i> Berlin, Germany
                                            </Link>
                                        </li>*/}
                                                </ul>
                                            ) : null}
                                            <h4 className="event-title">
                                                <Link to={`/events-details/${item.id}`}>{item?.title}</Link>
                                            </h4>
                                            <p>{item?.short_description}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </Masonry>
        </>
    );
};

export default EventsContent;

import React from 'react';

import SweetAlert from 'react-bootstrap-sweetalert';
import { modalTypes } from 'types';
import { ALERT_CUSTOM, ALERT_DANGER, ALERT_INFO, ALERT_SUCCESS, ALERT_WARNING } from 'utils/constants';

export const InfoModal = ({
    callBack,
    modalClose,
    isShowCancel,
    type,
    message,
    confirmText,
    cancelText,
    customIcon,
    sub_message,
    isShowConfirm,
}: modalTypes.IInfoModal) => {
    return (
        <div className="info-modal">
            <SweetAlert
                showCancel={isShowCancel}
                showConfirm={isShowConfirm}
                success={type === ALERT_SUCCESS}
                warning={type === ALERT_WARNING}
                info={type === ALERT_INFO}
                danger={type === ALERT_DANGER}
                custom={type === ALERT_CUSTOM}
                customIcon={type === ALERT_CUSTOM ? customIcon : ''}
                title={message}
                onConfirm={callBack}
                onCancel={modalClose}
                confirmBtnText={confirmText}
                confirmBtnBsStyle={type}
                cancelBtnText={cancelText}
                cancelBtnBsStyle="light"
                allowEscape
                closeOnClickOutside={false}
            >
                {sub_message}
            </SweetAlert>
        </div>
    );
};

import React, { useEffect } from 'react';

import { config } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { testimonialsSaga } from 'reduxToolkit/actions';
import { storeTypes, testimonialTypes } from 'types';

import bg7 from '../assets/images/background/bg7.jpg';
// import testiPic1 from '../assets/images/testimonials/pic1.jpg';

const Testimonial3 = () => {
    const settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const dispatch = useDispatch();

    const { testimonials } = useSelector((state: storeTypes.IStore) => state);
    const { data: testimonialData } = testimonials;

    useEffect(() => {
        dispatch(testimonialsSaga());
    }, []);

    return (
        <>
            {testimonialData?.length ? (
                <div
                    className="section-area section-sp2"
                    style={{
                        backgroundImage: `url(${bg7})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 heading-bx style1 text-center">
                                <h2 className="title-head">What People Say</h2>
                                {/* <p>
                                It is a long established fact that a reader will be distracted by the readable content
                                of a page
                            </p> */}
                            </div>
                        </div>

                        <Slider {...settings} className="testimonial-carousel-2 slick-slider owl-btn-1 owl-none">
                            {testimonialData.map((item: testimonialTypes.ITestimonialData, index) => {
                                const imgBaseURL = config.imgBaseURL;
                                const fileURL = item?.file_path;
                                return (
                                    <div key={index} className="slider-item">
                                        <div className="testimonial-bx style1">
                                            <div className="testimonial-head">
                                                {item?.file_path ? (
                                                    <div className="testimonial-thumb">
                                                        <img src={`${imgBaseURL}/${fileURL}`} alt="" />
                                                    </div>
                                                ) : null}
                                                <div className="testimonial-info">
                                                    <h5 className="name">{item?.name}</h5>
                                                    <p>-{item?.position}</p>
                                                </div>
                                            </div>
                                            <div className="testimonial-content">
                                                <p>{item?.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Testimonial3;

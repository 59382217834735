// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import {
    CONTACT_EMAIL2,
    CONTACT_EMAIL,
    CONTACT_MOBILE_NUMBER,
    FACEBOOK_LINK,
    INSTAGRAM_LINK,
    LINKED_IN_LINK,
    TELEGRAM_LINK,
    TWITTER_LINK,
    YOUTUBE_LINK,
} from '../utils/constants';

class ContactInfoCard extends Component {
    render() {
        return (
            <>
                <div className="bg-primary text-white contact-info-bx">
                    <div className="heading-bx left mb-4">
                        <h3 className="m-b10 title-head">
                            Contact <span> Info</span>
                        </h3>
                        {/* <p className="m-b0">It is a long established fact that a reader will be distracted</p> */}
                    </div>
                    <div className="widget widget_getintuch">
                        <ul>
                            <li>
                                <i className="ti-location-pin"></i>No.19, Peelamedu, Sri Nagar, Hope College,
                                Coimbatore, Tamil Nadu 641004
                            </li>
                            <li>
                                <i className="ti-location-pin"></i>ST-2, Sen Tower, Suramangalam Main Rd, <br /> opp. to
                                St.Joesph School, Thiruvakavundanur, Salem, Tamil Nadu 636005
                            </li>
                            <li>
                                <i className="ti-mobile"></i>+{CONTACT_MOBILE_NUMBER} (24/7 Support Line)
                            </li>
                            {CONTACT_EMAIL ? (
                                <li>
                                    <i className="ti-email"></i>
                                    {CONTACT_EMAIL}
                                </li>
                            ) : null}
                            <li>
                                <i className="ti-email"></i>
                                {CONTACT_EMAIL2}
                            </li>
                        </ul>
                    </div>
                    <h5 className="m-t0 m-b20">Follow Us</h5>
                    <ul className="list-inline contact-social-bx m-b0">
                        <li>
                            <a rel="noreferrer" target="_blank" href={FACEBOOK_LINK} className="btn outline radius-xl">
                                <i className="fa fa-facebook" />
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" target="_blank" href={TWITTER_LINK} className="btn outline radius-xl">
                                <i className="fa fa-twitter" />
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" target="_blank" href={INSTAGRAM_LINK} className="btn outline radius-xl">
                                <i className="fa fa-instagram" />
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" target="_blank" href={LINKED_IN_LINK} className="btn outline radius-xl">
                                <i className="fa fa-linkedin" />
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" target="_blank" href={TELEGRAM_LINK} className="btn outline radius-xl">
                                <i className="fa fa-telegram" />
                            </a>
                        </li>
                        <li>
                            <a rel="noreferrer" target="_blank" href={YOUTUBE_LINK} className="btn outline radius-xl">
                                <i className="fa fa-youtube" />
                            </a>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default ContactInfoCard;

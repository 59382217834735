import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';

// Layout
import { contactsSlice } from 'reduxToolkit/reducers';

import bannerImg from '../assets/images/banner/banner2.jpg';
import { InfoModal } from '../components/InfoModal';
import ContactInfoCard from '../components/contact-info-card';
import PublicLayout from '../layout/PublicLayout';
import { contactsSaga } from '../reduxToolkit/actions';
import { storeTypes, contactTypes } from '../types';
import { ALERT_SUCCESS, ALERT_DANGER, validationMsg } from '../utils/constants';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isNumberOnly } from '../utils/helpers';
// Elements

// Images

const ContactUs = () => {
    const dispatch = useDispatch();

    const { contacts } = useSelector((state: storeTypes.IStore) => state);
    const { isSuccess, error, message } = contacts;

    const { contactsReset } = contactsSlice.actions;

    // Form Initial Values
    const initialValues: contactTypes.IContactForm = {
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        contactMessage: '',
    };

    const contactValidation = {
        name: Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED),
        subject: Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED),
        contactMessage: Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED),
        email: Yup.string()
            .trim(validationMsg.REQUIRED)
            .email(validationMsg.VALID_EMAIL)
            .required(validationMsg.REQUIRED),
        phoneNumber: Yup.string().required(validationMsg.REQUIRED),
    };
    const validationSchema = Yup.object().shape(contactValidation);

    // HookForm
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues,
    });

    const onSubmit = useCallback(
        (values: contactTypes.IContactForm) => {
            dispatch(contactsSaga(values));
        },
        [dispatch]
    );

    const [showDialog, setShowDialog] = useState(false);
    const [showMsg, setShowMsg] = useState('');

    useEffect(() => {
        if (isSuccess === true && message) {
            setShowDialog(true);
            setShowMsg(message);
        } else if (isSuccess === false && error) {
            setShowDialog(true);
            setShowMsg(error);
        }
    }, [isSuccess]);

    return (
        <PublicLayout>
            {showDialog ? (
                <InfoModal
                    callBack={() => {
                        setShowDialog(false);
                        dispatch(contactsReset());
                    }}
                    modalClose={() => {
                        setShowDialog(false);
                        dispatch(contactsReset());
                    }}
                    isShowCancel={false}
                    type={isSuccess === true ? ALERT_SUCCESS : ALERT_DANGER}
                    message={showMsg}
                    confirmText={'OK'}
                />
            ) : null}

            <div className="page-content">
                <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                    <div className="container">
                        <div className="page-banner-entry">
                            <h1 className="text-white">Contact Us</h1>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    <div className="page-banner contact-page section-sp1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 m-b30">
                                    <form
                                        method="post"
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="contact-bx ajax-form"
                                    >
                                        <div className="ajax-message"></div>
                                        <div className="heading-bx left">
                                            <h3 className="title-head">
                                                Get In <span>Touch</span>
                                            </h3>
                                            {/* <p>
                                                It is a long established fact that a reader will be distracted by the
                                                readable content of a page
                                            </p> */}
                                        </div>
                                        <div className="row placeani">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            {...register('name')}
                                                            name="name"
                                                            type="text"
                                                            placeholder="Your Name"
                                                            className="form-control valid-character"
                                                        />

                                                        {errors.name?.message && (
                                                            <small className="error__txt">{errors.name?.message}</small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            {...register('email')}
                                                            name="email"
                                                            type="email"
                                                            placeholder="Your Email Address"
                                                            className="form-control"
                                                        />
                                                        {errors.email?.message && (
                                                            <small className="error__txt">
                                                                {errors.email?.message}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            maxLength={10}
                                                            onKeyPress={isNumberOnly}
                                                            {...register('phoneNumber')}
                                                            name="phoneNumber"
                                                            type="text"
                                                            placeholder="Your Phone"
                                                            className="form-control int-value"
                                                        />
                                                        {errors.phoneNumber?.message && (
                                                            <small className="error__txt">
                                                                {errors.phoneNumber?.message}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            {...register('subject')}
                                                            name="subject"
                                                            type="text"
                                                            placeholder="Subject"
                                                            className="form-control"
                                                        />
                                                        {errors.subject?.message && (
                                                            <small className="error__txt">
                                                                {errors.subject?.message}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <textarea
                                                            {...register('contactMessage')}
                                                            name="contactMessage"
                                                            placeholder="Type Message"
                                                            rows={4}
                                                            className="form-control"
                                                        ></textarea>
                                                        {errors.contactMessage?.message && (
                                                            <small className="error__txt">
                                                                {errors.contactMessage?.message}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <button
                                                    name="submit"
                                                    type="submit"
                                                    value="Submit"
                                                    className="btn button-md"
                                                >
                                                    {' '}
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-5 col-md-5 m-b30">
                                    <ContactInfoCard />
                                </div>
                                <div className="clearfix" />
                                <div className="col-lg-12">
                                    <iframe
                                        title={'1'}
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15664.704431706008!2d77.0203233!3d11.0254102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe907b8a2d9f0e2c1!2sPrashan%20Medical%20Technologies!5e0!3m2!1sen!2sin!4v1627117377695!5m2!1sen!2sin"
                                        height="450"
                                        style={{ width: '100%' }}
                                        allowFullScreen
                                        loading="lazy"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default ContactUs;

// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// Images
import pic1 from '../../assets/images/slider/pic1.png';
import Image1 from '../../assets/images/slider/slide3.jpg';

const Slider2 = () => {
    const settings = {
        dots: true,
        infinite: true,
    };

    return (
        <>
            <Slider {...settings} className="tt-slider slider-two slider-sp0 owl-none">
                <div className="slider-item">
                    <div className="slider-thumb">
                        <img src={Image1} alt="" />
                    </div>
                    <div className="slider-content">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="content-inner">
                                        <h6 className="sub-title">Welcome to Prashan Medical Technologies</h6>
                                        <h4 style={{ fontSize: 50 }} className="title text-primary">
                                            Own your future with Team Prashan...!!!{' '}
                                        </h4>
                                        <p>
                                            Prashan Medical Technologies was founded in 2017 by Mr. Boopathi Sakthivel.
                                            Our founder is having almost 10years of experience in the medical industry.
                                            He has worked in India, UAE, Germany, etc., Prashan Medical Technologies is
                                            the Authorized Service Provider for Schiller Healthcare India Pvt Ltd and
                                            sole proprietorship healthcare company in the India and ranks ...
                                        </p>
                                        <Link className="btn radius-xl m-r15 button-md white" to="/contact-us">
                                            Contact Us
                                        </Link>
                                        <Link className="btn radius-xl button-md" to="/about-us">
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="slider-img">
                                        <img src={pic1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider-item">
                    <div className="slider-thumb">
                        <img src={Image1} alt="" />
                    </div>
                    <div className="slider-content">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="content-inner">
                                        <h6 className="sub-title">Welcome to Prashan Medical Technologies 2</h6>
                                        <h4 style={{ fontSize: 50 }} className="title text-primary">
                                            Own your future with hands-on experience{' '}
                                        </h4>
                                        <p>
                                            Prashan Medical Technologies was founded in 2017 by Mr. Boopathi Sakthivel.
                                            Our founder is having almost 10years of experience in the medical industry.
                                            He has worked in India, UAE, Germany, etc., Prashan Medical Technologiesis
                                            the Authorized Service Provider for Schiller Healthcare India Pvt Lts and
                                            sole proprietorship healthcare company in the India and ranks ...
                                        </p>
                                        <Link className="btn radius-xl m-r15 button-md white" to="/contact-us">
                                            Contact Us
                                        </Link>
                                        <Link className="btn radius-xl button-md" to="/about-us">
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="slider-img">
                                        <img src={pic1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
};

export default Slider2;

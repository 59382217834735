import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonApiTypes, studentRegistrationTypes } from 'types';

const initialState: studentRegistrationTypes.IStudentRegistration = {
    registerType: '',
    email: '',
    name: '',
    mobileNumber: '',
    collegeName: '',
    department: '',
    whichYear: '',
    nationality: '',
    state: '',
    city: '',

    event_id: null,

    isLoading: false,
    message: '',
    errors: [],
    error: '',
};

const studentRegistrationSlice = createSlice({
    name: 'studentRegistration',
    initialState: initialState,
    reducers: {
        studentRegistrationReset: () => {
            return {
                ...initialState,
            };
        },
        studentRegistrationStart: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        studentRegistrationSuccess: (
            state,
            action: PayloadAction<studentRegistrationTypes.IStudentRegistrationResponse>
        ) => {
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                isSuccess: true,
                errors: [],
                error: '',
            };
        },
        studentRegistrationFailed: (state, action: PayloadAction<commonApiTypes.IErrorData>) => {
            return {
                ...state,
                errors: action.payload?.errors || [],
                error: action.payload.error,
                message: '',
                isLoading: false,
                isSuccess: false,
            };
        },
    },
});

export { studentRegistrationSlice };

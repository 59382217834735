// eslint-disable-next-line no-use-before-define
import React, { Component, useEffect, useState } from 'react';
import { FullPageLoader } from 'components/shared/loader/FullPageLoader';
import Masonry from 'react-masonry-component';
import { Link } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox';

import { apiCall, apiRoutes } from 'utils/helpers';
import { config } from 'config';

// Magnific Anchor
const MagnificAnchor = (props: { imageToOpen: any }) => {
    const { openLightbox } = useLightbox();

    return (
        <Link to={'#'} onClick={() => openLightbox(props.imageToOpen)} className="magnific-anchor">
            <i className="ti-search"></i>
        </Link>
    );
};

const options = {
    settings: {
        overlayColor: 'rgba(0,0,0,0.9)',
        backgroundColor: '#f7b205',
        slideAnimationType: 'slide',
    },
    buttons: {
        backgroundColor: '#f7b205',
        iconColor: 'rgba(255, 255, 255, 1)',
        showAutoplayButton: false,
        showDownloadButton: false,
    },
    caption: {
        captionColor: '#a6cfa5',
        captionFontFamily: 'Raleway, sans-serif',
        captionFontWeight: '300',
        captionTextTransform: 'uppercase',
    },
};

const MasonryContent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [imgData, setImgData] = useState([]);

    const dispathcAPI = async () => {
        setIsLoading(true);
        const response = await apiCall({ ...apiRoutes.getGallery });
        if (response?.data?.status) {
            setImgData(response.data.data);
            console.log('response', response?.data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        dispathcAPI();
    }, []);

    return (
        <>
            <FullPageLoader isLoad={isLoading} />

            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <Masonry className="ttr-gallery-listing">
                        {imgData.map((item: any, index) => {
                            const imgBaseURL = config.imgBaseURL;
                            const fileURL = item?.file_path;
                            return (
                                <div className="action-card col-lg-3 col-md-4 col-sm-6" key={index}>
                                    <div className="ttr-box portfolio-bx">
                                        <div className="ttr-media media-ov2 media-effect">
                                            {item?.file_path ? <img src={`${imgBaseURL}/${fileURL}`} alt="" /> : null}
                                            <div className="ov-box">
                                                <div className="overlay-icon align-m">
                                                    <MagnificAnchor imageToOpen={undefined} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Masonry>
                </SRLWrapper>
            </SimpleReactLightbox>
        </>
    );
};

class PortfolioContent extends Component {
    render() {
        return (
            <>
                <MasonryContent />
            </>
        );
    }
}

export default PortfolioContent;

import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { STUDENT_REGISTRATION_SAGA } from 'reduxToolkit/actions';
import { commonSlice, studentRegistrationSlice } from 'reduxToolkit/reducers';
import { studentRegistrationTypes } from 'types';
import { apiRoutes, apiCall, getApiErrorMessage } from 'utils/helpers';

const {
    studentRegistrationStart,
    studentRegistrationSuccess,
    studentRegistrationFailed,
} = studentRegistrationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const studentRegistrationSaga = function* (
    eventPayload: PayloadAction<studentRegistrationTypes.IStudentRegistrationForm>
): any {
    yield put(studentRegistrationStart());
    try {
        const payloadData = eventPayload.payload;
        const data: studentRegistrationTypes.IStudentRegistrationForm = {
            email: payloadData.email,
            registerType: payloadData.registerType,
            event_id: payloadData.event_id,
        };

        if (payloadData.registerType === 'newUser') {
            data.name = payloadData.name;
            data.mobileNumber = payloadData.mobileNumber;
            data.collegeName = payloadData.collegeName;
            data.department = payloadData.department;
            data.whichYear = payloadData.whichYear;
            data.nationality = payloadData.nationality;
            data.state = payloadData.state;
            data.city = payloadData.city;
        }

        const response = yield apiCall({ data, ...apiRoutes.studentRegister });
        if (response.status === 200) {
            yield put(studentRegistrationSuccess(response.data));
        } else {
            const errorData = {
                success: false,
                error: getApiErrorMessage(response),
            };
            yield put(studentRegistrationFailed(errorData));
        }
    } catch (error) {
        yield put(exceptionHandlerRequest(error));
    }
};

export const takeStudentRegistrationSagaRequest = function* () {
    yield takeEvery(STUDENT_REGISTRATION_SAGA, studentRegistrationSaga);
};

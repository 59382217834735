import axios, { AxiosResponse, AxiosError } from 'axios';
import { config } from 'config';
import { commonApiTypes } from 'types';
// import { COOKIES_NAME } from 'utils/constants';
// import { removeCookie } from 'utils/helpers';

export const apiCall = async (apiConfig: commonApiTypes.ApiConfig, promisePayload?: any): Promise<any> => {
    try {
        console.log(apiConfig);

        const baseURL = config.apiBaseURL;
        const url = `${baseURL}${apiConfig.apiPath}`;
        const requestConfig: any = {
            url,
            method: apiConfig.action,
            data: apiConfig.data,
            headers: defaultHeader(),
            params: null,
            withCredentials: true,
            timeout: 0,
            mode: 'no-cors',
            credentials: 'same-origin',
            validateStatus(status: number) {
                //If user un authorized
                if (status === 401) {
                    // removeCookie(COOKIES_NAME.IS_LOG_IN, true, '/');
                    // removeCookie(COOKIES_NAME.IS_ACCT_VERIFIED, true, '/');
                }
                return status >= 200 && status <= 500;
            },
        };
        const response = await axios(requestConfig)
            .then((res: AxiosResponse<any>) => {
                return {
                    ...res,
                    ...(promisePayload && { promisePayload }),
                };
            })
            .catch((error: AxiosError<any>) => {
                return error;
                //Todo API Errors handle
            });
        return response;
    } catch (ex) {
        return false;
    }
};

export const defaultHeader = () => {
    const headers: commonApiTypes.IDefaultHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        withCredentials: true,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
    };
    return headers;
};

// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

// Images

class OurMission extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true });
    }

    render() {
        return (
            <>
                <div className="section-area bg-gray section-sp1 our-story">
                    <div className="container">
                        <div className="row align-items-center d-flex">
                            <div className="col-lg-12 col-md-12 m-b30">
                                <div className="heading-bx left mb-3">
                                    <h2 className="title-head">
                                        Our <span>Vision</span>
                                    </h2>
                                </div>
                                <p className="m-b30">
                                    To be a centre of innovation, providing world-class hands-on technical education
                                    that transform individuals into intellectual, empathetic and responsible citizens
                                    and to provide the excellent product to the healthcare society.
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 m-b30">
                                <div className="heading-bx left mb-3">
                                    <h2 className="title-head">
                                        Our <span>Mission</span>
                                    </h2>
                                </div>
                                <p className="m-b30">
                                    <ul style={{ listStylePosition: 'inside' }}>
                                        <li>
                                            To provide world class training opportunities that is most conducive for
                                            learning.
                                        </li>
                                        <li>
                                            To engage committed technical experts who will infuse practical knowledge
                                            with latest teaching pedagogies.
                                        </li>
                                        <li>
                                            To provide state-of-the-art facilities to the members of faculty and student
                                            fraternity to access and engage in diligent product development.
                                        </li>
                                        <li>
                                            To collaborate the best minds in the institution thereby empowering the
                                            young minds to meet the global standards.
                                        </li>
                                        <li>
                                            To create a friendly environment for a continual progress and change that
                                            respects heterogeneity.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default OurMission;

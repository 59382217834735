import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonApiTypes, contactTypes } from 'types';

const initialState: contactTypes.IContact = {
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    contactMessage: '',

    isLoading: false,
    message: '',
    errors: [],
    error: '',
};

const contactsSlice = createSlice({
    name: 'contactsSlice',
    initialState: initialState,
    reducers: {
        contactsReset: () => {
            return {
                ...initialState,
            };
        },
        contactsStart: (state, action: PayloadAction<contactTypes.IContactForm>) => {
            return {
                ...state,
                ...action.payload,
                isLoading: true,
            };
        },
        contactsSuccess: (state, action: PayloadAction<contactTypes.IContactResponse>) => {
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                isSuccess: true,
                errors: [],
                error: '',
            };
        },
        contactsFailed: (state, action: PayloadAction<commonApiTypes.IErrorData>) => {
            return {
                ...state,
                errors: action.payload?.errors || [],
                error: action.payload.error,
                message: '',
                isLoading: false,
                isSuccess: false,
            };
        },
    },
});

export { contactsSlice };

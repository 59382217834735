import React from 'react';

import { Link } from 'react-router-dom';

// Layout

// Elements

// Images
import bannerImg from '../assets/images/banner/banner2.jpg';
import blogPic1 from '../assets/images/blog/default/thum1.jpg';
import BlogAside from '../components/blog-aside';
import PublicLayout from '../layout/PublicLayout';

const BlogDetails = () => {
    return (
        <PublicLayout>
            <div className="page-content">
                <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                    <div className="container">
                        <div className="page-banner-entry">
                            <h1 className="text-white">Blog Details</h1>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Blog Details</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    <div className="section-area section-sp2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-xl-8">
                                    <div className="recent-news blog-lg">
                                        <div className="action-box blog-lg">
                                            <img src={blogPic1} alt="" />
                                        </div>
                                        <div className="info-bx">
                                            <ul className="media-post">
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-calendar"></i>May 14 2019
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-comments-o"></i>10 Comment
                                                    </Link>
                                                </li>
                                            </ul>
                                            <h3 className="post-title">
                                                Seven Reasons You Should Fall In Love With Education.
                                            </h3>
                                            <p>
                                                It is used every day in all types of businesses; Email newsletters,
                                                websites, print and online advertisements, presentations, social media
                                                updates, flyers, and brochures; the list goes on and on
                                            </p>
                                            <p>
                                                David Ogilvy, the advertising legend once said that, On average, five
                                                times as many people read the headline as read the body copy. When you
                                                have written your headline, you have spent eighty cents out of your
                                                dollar." As Ogilvy points out, your headline is the first (and sometimes
                                                the only) thing that your audience will read.
                                            </p>
                                            <p>
                                                You just need to enter the keyword and select the keyword type to
                                                generate a list of 6 title ideas and suggestions. If you’re not
                                                satisfied with the results, you can always hit the refresh button to
                                                generate a new list of unique titles.
                                            </p>
                                            <p>
                                                Once you’ve gotten all the titles and have chosen the best one, the next
                                                thing you need to do is to craft a magnetic content. Great content
                                                marketers excel at creating content that their readers crave, but even
                                                the best struggle with delivering content to the right person at the
                                                right time.
                                            </p>
                                            <div className="ttr-divider bg-gray">
                                                <i className="icon-dot c-square"></i>
                                            </div>

                                            <div className="ttr-divider bg-gray">
                                                <i className="icon-dot c-square"></i>
                                            </div>

                                            <div className="ttr-divider bg-gray">
                                                <i className="icon-dot c-square"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-4">
                                    <BlogAside />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default BlogDetails;

import OurMission from 'components/our-story/our_misson';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

// Layout
import bannerImg from '../assets/images/banner/banner2.jpg';
import Counter from '../components/counter/counter';
import OurStory1 from '../components/our-story/our-story1';
import ServicesContent1 from '../components/services-content-1';
import PublicLayout from '../layout/PublicLayout';

// Elements

// Images

class AboutUs extends Component {
    render() {
        return (
            <PublicLayout>
                <div className="page-content">
                    <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">About Us</h1>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-row">
                        <div className="container">
                            <ul className="list-inline">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>About Us</li>
                            </ul>
                        </div>
                    </div>

                    <div className="content-block">
                        <OurMission />
                        <OurStory1 />

                        <Counter />

                        <ServicesContent1 />
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default AboutUs;

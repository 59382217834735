export const COUNTER = [
    {
        label: 'Trained Students',
        value: 2000,
    },
    {
        label: 'Career Achievers',
        value: 50,
    },
    {
        label: 'Conducted Trainings',
        value: 300,
    },
    {
        label: 'Expert Sessions',
        value: 200,
    },
    {
        label: 'MoU Signed',
        value: 15,
    },
    {
        label: 'Clients',
        value: 100,
    },
];

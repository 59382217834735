// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

// Images
import VideoCover from '../../assets/images/media/video_cover.JPG';
// import VideoCover from '../../assets/images/media/video_cover.PNG';

class OurStory2 extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true });
    }

    render() {
        return (
            <>
                <div className="section-area bg-primary section-sp1 our-story popp">
                    <div className="container">
                        <div className="row align-items-center d-flex">
                            <div className="col-lg-5 col-md-12 text-white m-b30">
                                <div className="heading-bx style1 mb-3">
                                    <h2 className="title-head">Our Story</h2>
                                    <p className="text-white">
                                        Prashan Medical Technologies was founded in 2017 by Mr. Boopathi Sakthivel. Our
                                        founder is having almost 10years of experience in the medical industry.
                                    </p>
                                </div>
                                <p className="m-b30">
                                    He has worked in India, UAE, Germany, etc., Prashan Medical Technologies is the
                                    Authorized Service Provider for Schiller Healthcare India Pvt Ltd and sole
                                    proprietorship healthcare company in the India and ranks ...
                                </p>
                                <Link to="/about-us" className="btn">
                                    Read More
                                </Link>
                            </div>
                            <div className="col-lg-7 col-md-12 heading-bx p-lr">
                                <div className="video-bx">
                                    <img src={VideoCover} alt="" />
                                    <Link onClick={this.openModal} to="#" className="popup-youtube video">
                                        <i className="fa fa-play" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="qf4pegdkOvU"
                    onClose={() => this.setState({ isOpen: false })}
                />
            </>
        );
    }
}

export default OurStory2;

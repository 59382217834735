import { config } from 'config';
import cookie from 'cookie';
import Cookies, { CookieAttributes } from 'js-cookie';
import { customToString } from 'utils/helpers';

let cookieParams: CookieAttributes = {};
if (config.currentPlatform !== 'local') {
    cookieParams = { domain: '.archerreview.com', secure: true, sameSite: 'Lax' };
}

export const isLoggedIn = (reqCookies = null) => {
    // if we don't have request cookies, get the cookie from client
    if (!reqCookies) {
        return !!Cookies.get('is_logged_in');
    }
    // otherwise get cookie from server
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!cookie.parse(reqCookies).is_logged_in;
};

export const setCookie = (cookieName: string, cookieValue: string, isReload = false) => {
    Cookies.set(cookieName, cookieValue, cookieParams);
    if (isReload) {
        window.location.reload();
    }
};

export const getCookie = (cookieName: string) => {
    if (!Cookies.get(cookieName)) {
        return null;
    }
    return JSON.parse(customToString(Cookies.get(cookieName)));
};

export const removeCookie = (cookieName: string, isRedirect = false, redirectRoute = '') => {
    Cookies.remove('archerreview_session', cookieParams);
    Cookies.remove(cookieName, cookieParams);
    if (isRedirect) {
        window.location.href = redirectRoute;
    }
};

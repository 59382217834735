import { config } from 'config';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ShowMoreText from 'react-show-more-text';
import { Link } from 'react-router-dom';
import { apiCall, apiRoutes } from 'utils/helpers';

// Layout

// Images

import bannerImg from '../assets/images/banner/banner3.jpg';
import coursesPic1 from '../assets/images/courses/pic1.jpg';
// import coursesPic2 from '../assets/images/courses/pic2.jpg';
// import coursesPic3 from '../assets/images/courses/pic3.jpg';
// import coursesPic4 from '../assets/images/courses/pic4.jpg';
// import coursesPic5 from '../assets/images/courses/pic5.jpg';
// import coursesPic6 from '../assets/images/courses/pic6.jpg';
// import coursesPic7 from '../assets/images/courses/pic7.jpg';
// import coursesPic8 from '../assets/images/courses/pic8.jpg';
// import coursesPic9 from '../assets/images/courses/pic9.jpg';
import PublicLayout from '../layout/PublicLayout';
import { FullPageLoader } from 'components/shared';

const Courses = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [dataState, setDataState] = useState<any[]>([]);

    const getDataFunc = async (params: any) => {
        const response = await apiCall({ ...apiRoutes.getCourse, params });
        if (response?.data?.status) {
            setDataState(response?.data?.data || []);
            setIsLoading(false);
        }
    };

    const dispatchData = useCallback(
        async (pageNumber: number, searchingWord: string) => {
            const payload = {
                page: pageNumber,
                search_word: searchingWord,
            };
            await getDataFunc(payload);
        },
        [dispatch]
    );

    useEffect(() => {
        dispatchData(1, '');
    }, [dispatchData]);

    return (
        <PublicLayout>
            {isLoading ? <FullPageLoader /> : ''}
            <div className="page-content">
                <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                    <div className="container">
                        <div className="page-banner-entry">
                            <h1 className="text-white">Our Courses</h1>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Our Courses</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    <div className="section-area section-sp1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="row">
                                        {dataState?.length
                                            ? dataState.map((item: any) => {
                                                  const imgBaseURL = config.imgBaseURL;
                                                  const fileURL = item?.file_path;
                                                  return (
                                                      <div className="col-md-6 col-lg-4 col-sm-6 m-b30">
                                                          <div className="cours-bx">
                                                              <div className="action-box">
                                                                  <img
                                                                      src={
                                                                          fileURL
                                                                              ? `${imgBaseURL}/${fileURL}`
                                                                              : coursesPic1
                                                                      }
                                                                      alt=""
                                                                  />
                                                              </div>
                                                              <div className="info-bx">
                                                                  <h6>
                                                                      <ShowMoreText
                                                                          /* Default options */
                                                                          lines={1}
                                                                          more=""
                                                                          less=""
                                                                      >
                                                                          {item.title}
                                                                      </ShowMoreText>
                                                                  </h6>
                                                                  <ShowMoreText
                                                                      /* Default options */
                                                                      lines={2}
                                                                      more=""
                                                                      less=""
                                                                  >
                                                                      {item.short_description}
                                                                  </ShowMoreText>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                            : 'No Courses'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Courses;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonApiTypes, testimonialTypes } from 'types';

const initialState: testimonialTypes.ITestimonial = {
    isLoading: false,
    message: '',
    errors: [],
    error: '',
    data: [],
};

const testimonialSlice = createSlice({
    name: 'testimonial',
    initialState: initialState,
    reducers: {
        testimonialReset: () => {
            return {
                ...initialState,
            };
        },
        testimonialStart: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        testimonialSuccess: (state, action: PayloadAction<testimonialTypes.ITestimonialResponse>) => {
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                isSuccess: true,
                errors: [],
                error: '',
            };
        },
        testimonialFailed: (state, action: PayloadAction<commonApiTypes.IErrorData>) => {
            return {
                ...state,
                errors: action.payload?.errors || [],
                error: action.payload.error,
                message: '',
                isLoading: false,
                isSuccess: false,
            };
        },
    },
});

export { testimonialSlice };

import { envTypes } from 'types';

/*
export const config: envTypes.IEnv = {
    apiBaseURL: process.env.REACT_APP_BASE_API_BASE_URL,
    imgBaseURL: process.env.REACT_APP_BASE_FILE_BASE_URL,
    currentPlatform: process.env.REACT_APP_BASE_PLATFORM,
};
*/
//prashan-app-api.herokuapp.com/

export const config: envTypes.IEnv = {
    apiBaseURL: 'https://prashan-api.eshwara.xyz',
    imgBaseURL: 'https://prashan-api.eshwara.xyz',
    currentPlatform: 'production',
};

// export const config: envTypes.IEnv = {
//     apiBaseURL: 'http://127.0.0.1:8000',
//     imgBaseURL: 'http://127.0.0.1:8000',
//     currentPlatform: 'production',
// };

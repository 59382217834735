import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

// Images
import logo from '../../assets/files/logo_transparent.png';
import Msme from '../../assets/images/media/Partner/msme.webp';
import AICTE from '../../assets/images/media/Partner/AICTE.jpg';

import {
    ALERT_DANGER,
    ALERT_SUCCESS,
    FACEBOOK_LINK,
    INSTAGRAM_LINK,
    LINKED_IN_LINK,
    TELEGRAM_LINK,
    TWITTER_LINK,
    YOUTUBE_LINK,
} from '../../utils/constants';
import { FullPageLoader } from 'components/shared';
import { apiCall, apiRoutes } from 'utils/helpers';
import { InfoModal } from 'components/InfoModal';

const content = [
    {
        thumb: Msme,
    },
    {
        thumb: AICTE,
    },
];

const options = {
    settings: {
        overlayColor: 'rgba(0,0,0,0.9)',
        backgroundColor: '#FDC716',
        slideAnimationType: 'slide',
    },
    buttons: {
        backgroundColor: '#f7b205',
        iconColor: 'rgba(255, 255, 255, 1)',
        showDownloadButton: false,
        showAutoplayButton: false,
        showThumbnailsButton: false,
    },
    caption: {
        captionColor: '#232eff',
        captionFontFamily: 'Raleway, sans-serif',
        captionFontWeight: '300',
        captionTextTransform: 'uppercase',
    },
};

// eslint-disable-next-line func-style
function GalleryImg() {
    return (
        <>
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <ul className="magnific-image">
                        {content.map((item, index) => (
                            <li key={index}>
                                <img src={item.thumb} alt="" />
                            </li>
                        ))}
                    </ul>
                </SRLWrapper>
            </SimpleReactLightbox>
        </>
    );
}

const Footer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [email, setEmail] = React.useState('');

    const dispatchNewsLetter = useCallback(async () => {
        setIsLoading(true);
        const data = {
            email: email,
        };
        const response = await apiCall({ data, ...apiRoutes.addNewsLetter });
        if (response?.data?.status) {
            setIsSuccess(true);
            setIsLoading(false);
            setShowDialog(true);
        } else {
            setErrorMsg(response?.data?.error || 'Something went wrong');
            setIsSuccess(false);
            setIsLoading(false);
            setShowDialog(true);
        }
    }, [email]);

    return (
        <>
            <FullPageLoader isLoad={isLoading} />

            {showDialog ? (
                <InfoModal
                    callBack={() => {
                        setShowDialog(false);
                    }}
                    modalClose={() => {
                        setShowDialog(false);
                    }}
                    isShowCancel={false}
                    type={isSuccess === true ? ALERT_SUCCESS : ALERT_DANGER}
                    message={isSuccess ? 'Newsletter Subscribed Successfully' : errorMsg}
                    confirmText={'OK'}
                />
            ) : null}

            <footer>
                <div className="footer-top">
                    <div className="pt-exebar">
                        <div className="container">
                            <div className="d-flex align-items-stretch">
                                <div className="pt-logo mr-auto">
                                    <Link to="/">
                                        <img style={{ width: 200 }} src={logo} alt="" />
                                    </Link>
                                </div>
                                <div className="pt-social-link">
                                    <ul className="list-inline m-a0">
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={FACEBOOK_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={TWITTER_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={INSTAGRAM_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={LINKED_IN_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-linkedin" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={TELEGRAM_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-telegram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={YOUTUBE_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pt-btn-join">
                                    <Link to="/registration" className="btn">
                                        Join Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12 footer-col-4">
                                <div className="widget">
                                    <h6 className="footer-title">Sign Up For A Newsletter</h6>
                                    <p className="text-capitalize m-b20">To receive latest updates</p>
                                    <div className="subscribe-form m-b20">
                                        <form className="subscription-form">
                                            <div className="ajax-message"></div>
                                            <div className="input-group">
                                                <input
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    name="email"
                                                    required={true}
                                                    className="form-control"
                                                    placeholder="Your Email Address"
                                                    type="email"
                                                />
                                                <span className="input-group-btn">
                                                    <button
                                                        name="submit"
                                                        value="Submit"
                                                        onClick={() => dispatchNewsLetter()}
                                                        type="button"
                                                        className="btn"
                                                    >
                                                        <i className="fa fa-arrow-right"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 col-md-7 col-sm-12">
                                <div className="row">
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4">
                                        <div className="widget footer_widget">
                                            <h6 className="footer-title">Company</h6>
                                            <ul>
                                                <li>
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li>
                                                    <Link to="/about-us">About Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact-us">Contact Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Career</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4">
                                        <div className="widget footer_widget">
                                            <h6 className="footer-title">Get In Touch</h6>
                                            <ul>
                                                <li>
                                                    <Link to="/blog">Blog</Link>
                                                </li>
                                                <li>
                                                    <Link to="/portfolio">Portfolio</Link>
                                                </li>
                                                <li>
                                                    <Link to="/events">Events</Link>
                                                </li>
                                                <li>
                                                    <Link to="/profile">Instructor</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4">
                                        <div className="widget footer_widget">
                                            <h6 className="footer-title">Courses</h6>
                                            <ul>
                                                <li>
                                                    <Link to="/courses">Courses</Link>
                                                </li>

                                                {/* <li>
                                                    <Link to="/profile">Profile</Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/events">Upcoming events</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-5 col-sm-12 footer-col-4">
                                <div className="widget widget_gallery gallery-grid-4">
                                    <h5 className="footer-title">Our Gallery</h5>
                                    <GalleryImg />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                {' '}
                                © 2022 <span className="text-white">Prashan Med Tech</span> All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;

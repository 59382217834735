import { combineReducers } from 'redux';
import { storeTypes } from 'types';

import { testimonialSlice, eventsSlice, singleEventsSlice, contactsSlice, studentRegistrationSlice } from './reducers';

const appReducer = combineReducers<storeTypes.IStore>({
    testimonials: testimonialSlice.reducer,
    singleEvents: singleEventsSlice.reducer,
    events: eventsSlice.reducer,
    contacts: contactsSlice.reducer,
    studentRegistration: studentRegistrationSlice.reducer,
});

//Clear set persist
const rootReducer = (state: any, action: any) => {
    if (action.type === 'logOut/logOutSuccess') {
        state = undefined;
    }
    return appReducer(state, action);
};

export { rootReducer };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonApiTypes, eventsTypes } from 'types';

const initialState: eventsTypes.IEvents = {
    isLoading: false,
    message: '',
    errors: [],
    error: '',
    data: [],
    is_upcoming: false,
    limit: null,
};

const eventsSlice = createSlice({
    name: 'eventsSlice',
    initialState: initialState,
    reducers: {
        eventsReset: () => {
            return {
                ...initialState,
            };
        },
        eventsStart: (state, action: PayloadAction<eventsTypes.IEventForm>) => {
            return {
                ...state,
                ...action.payload,
                isLoading: true,
            };
        },
        eventsSuccess: (state, action: PayloadAction<eventsTypes.IEventFormResponse>) => {
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                isSuccess: true,
                errors: [],
                error: '',
            };
        },
        eventsFailed: (state, action: PayloadAction<commonApiTypes.IErrorData>) => {
            return {
                ...state,
                errors: action.payload?.errors || [],
                error: action.payload.error,
                message: '',
                isLoading: false,
                isSuccess: false,
            };
        },
    },
});

export { eventsSlice };

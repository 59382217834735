import { put, takeEvery } from 'redux-saga/effects';
import { TESTIMONIALS_SAGA } from 'reduxToolkit/actions';
import { commonSlice, testimonialSlice } from 'reduxToolkit/reducers';
import { apiRoutes, apiCall, getApiErrorMessage } from 'utils/helpers';

const { testimonialStart, testimonialSuccess, testimonialFailed } = testimonialSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const testimonialSaga = function* (): any {
    yield put(testimonialStart());
    try {
        const response = yield apiCall({ ...apiRoutes.testimonials });
        if (response.status === 200) {
            yield put(testimonialSuccess(response.data));
        } else {
            const errorData = {
                success: false,
                message: getApiErrorMessage(response),
            };
            yield put(testimonialFailed(errorData));
        }
    } catch (error) {
        yield put(exceptionHandlerRequest(error));
    }
};

export const takeTestimonialSagaRequest = function* () {
    yield takeEvery(TESTIMONIALS_SAGA, testimonialSaga);
};

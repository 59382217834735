import React, { useCallback, useState } from 'react';

// Images
import appBg from '../assets/images/background/appointment-bg.png';
import bg1 from '../assets/images/background/bg1.jpg';
import { studentRegistrationTypes } from '../types';
import * as Yup from 'yup';
import { validationMsg } from '../utils/constants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isNumberOnly } from '../utils/helpers';
import { useDispatch } from 'react-redux';
import { studentRegistrationSaga } from '../reduxToolkit/actions';

const AppointmentBox = React.memo(({ sessionId }: { sessionId: any }) => {
    const [type, setType] = useState('newUser');
    const dispatch = useDispatch();
    // Form Initial Values
    const initialValues: studentRegistrationTypes.IStudentRegistrationForm = {
        registerType: 'newUser',
        name: '',
        email: '',
        mobileNumber: '',
        collegeName: '',
        department: '',
        whichYear: '',
        nationality: '',
        state: '',
        city: '',
        event_id: sessionId,
    };

    const studentRegistrationValidation = {
        name: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED);
            }
        }),

        email: Yup.string()
            .trim(validationMsg.REQUIRED)
            .email(validationMsg.VALID_EMAIL)
            .required(validationMsg.REQUIRED),

        mobileNumber: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().min(10).max(10).required(validationMsg.REQUIRED);
            }
        }),

        collegeName: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED);
            }
        }),
        department: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED);
            }
        }),
        whichYear: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED);
            }
        }),
        nationality: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED);
            }
        }),
        state: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED);
            }
        }),
        city: Yup.string().when('registerType', (registerType: string): any => {
            if (registerType === 'newUser') {
                return Yup.string().trim(validationMsg.REQUIRED).required(validationMsg.REQUIRED);
            }
        }),
    };

    const validationSchema = Yup.object().shape(studentRegistrationValidation);

    // HookForm
    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues,
    });

    const onSubmit = useCallback((payloadValues: studentRegistrationTypes.IStudentRegistrationForm) => {
        dispatch(studentRegistrationSaga(payloadValues));
    }, []);
    return (
        <>
            <div
                className="section-area section-sp3 ovpr-dark bg-fix appointment-box"
                style={{ backgroundImage: `url(${bg1})` }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 heading-bx style1 text-white text-center">
                            <h2 className="title-head">Register Now</h2>
                            {/* <p>
                                    Lorem Ipsum has been the industry's standard dummy text ever since when an unknown
                                    printer took a galley.
                                </p>*/}
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} method="post" className="contact-bx ajax-form">
                        <div className="ajax-message" />
                        {sessionId ? (
                            <div className="row placeani">
                                <div className="col-lg-6">
                                    <label className="radio-inline">
                                        <input
                                            type="radio"
                                            checked={type === 'newUser'}
                                            onChange={(e) => {
                                                setValue('registerType', 'newUser');
                                                setType(e.target.value);
                                            }}
                                            name="registerType"
                                            value="newUser"
                                        />
                                        &nbsp;New Student
                                    </label>
                                </div>
                                <div className="col-lg-6">
                                    <label className="radio-inline">
                                        <input
                                            type="radio"
                                            checked={type === 'existingUser'}
                                            onChange={(e) => {
                                                setValue('registerType', 'existingUser');
                                                setType(e.target.value);
                                            }}
                                            name="registerType"
                                            value="existingUser"
                                        />
                                        &nbsp;Existing Student
                                    </label>
                                </div>
                            </div>
                        ) : null}
                        <div className="row placeani">
                            {type === 'newUser' ? (
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                {...register('name')}
                                                name="name"
                                                type="text"
                                                placeholder="Your Name"
                                                className="form-control valid-character"
                                            />
                                            {errors.name?.message && (
                                                <small className="error__txt">{errors.name?.message}</small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className={`${type === 'newUser' ? 'col-lg-6' : 'col-lg-12'}`}>
                                <div className="form-group">
                                    <div className="input-group">
                                        <input
                                            {...register('email')}
                                            name="email"
                                            type="email"
                                            placeholder="Your Email Address"
                                            className="form-control"
                                        />
                                        {errors.email?.message && (
                                            <small className="error__txt">{errors.email?.message}</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {type === 'newUser' ? (
                                <>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    maxLength={10}
                                                    onKeyPress={isNumberOnly}
                                                    {...register('mobileNumber')}
                                                    name="mobileNumber"
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    className="form-control int-value"
                                                />
                                                {errors.mobileNumber?.message && (
                                                    <small className="error__txt">{errors.mobileNumber?.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    {...register('collegeName')}
                                                    name="collegeName"
                                                    type="text"
                                                    placeholder="College Name"
                                                    className="form-control"
                                                />
                                                {errors.collegeName?.message && (
                                                    <small className="error__txt">{errors.collegeName?.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    {...register('department')}
                                                    name="department"
                                                    type="text"
                                                    placeholder="Department"
                                                    className="form-control"
                                                />
                                                {errors.department?.message && (
                                                    <small className="error__txt">{errors.department?.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <select
                                                    {...register('whichYear')}
                                                    name="whichYear"
                                                    className="form-control"
                                                >
                                                    <option value="">Select</option>
                                                    <option value="first">First</option>
                                                    <option value="second">Second</option>
                                                    <option value="preFinal">Pre Final</option>
                                                    <option value="finalYear">Final Year</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                {/* <input
                                                    {...register('whichYear')}
                                                    name="whichYear"
                                                    type="text"
                                                    placeholder="Which Year"
                                                    className="form-control"
                                                />*/}
                                                {errors.whichYear?.message && (
                                                    <small className="error__txt">{errors.whichYear?.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    {...register('nationality')}
                                                    name="nationality"
                                                    type="text"
                                                    placeholder="Nationality"
                                                    className="form-control"
                                                />
                                                {errors.nationality?.message && (
                                                    <small className="error__txt">{errors.nationality?.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    {...register('state')}
                                                    name="state"
                                                    type="text"
                                                    placeholder="State"
                                                    className="form-control"
                                                />
                                                {errors.state?.message && (
                                                    <small className="error__txt">{errors.state?.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    {...register('city')}
                                                    name="city"
                                                    type="text"
                                                    placeholder="City"
                                                    className="form-control"
                                                />
                                                {errors.city?.message && (
                                                    <small className="error__txt">{errors.city?.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-lg-12">
                                <div className="form-group">
                                    <div className="input-group">
                                        <textarea
                                            name="message"
                                            rows={4}
                                            placeholder="Type Message"
                                            className="form-control"
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                            </div>*/}
                                </>
                            ) : null}

                            <div className="col-lg-12">
                                <button name="submit" type="submit" value="Submit" className="btn button-md">
                                    Register
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <img src={appBg} className="appoint-bg" alt="" />
            </div>
        </>
    );
});

export default AppointmentBox;

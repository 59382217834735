import React from 'react';

export const letterOnly = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const inputValue = event.charCode;
    if (!(inputValue >= 65 && inputValue <= 90) && !(inputValue >= 97 && inputValue <= 122) && inputValue !== 0) {
        event.preventDefault();
    }
};

export const isNumberOnly = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = event.charCode;
    if (!(inputValue >= 48 && inputValue <= 57)) {
        event.preventDefault();
    }
};

export const isAlphaNumeric = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
};

export const letterSpace = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const inputValue = event.charCode;
    //&& (inputValue != 46)  DOt
    if (
        !(inputValue >= 65 && inputValue <= 90) &&
        !(inputValue >= 97 && inputValue <= 122) &&
        inputValue !== 32 &&
        inputValue !== 0
    ) {
        event.preventDefault();
    }
};

export const toFixed2 = (data: number) => {
    return data.toFixed(2);
};

export const jsonToQueryString = (queryParams: { [key: string]: any }) => {
    return Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');
};

export const queryStringToJSON = (queryString: string) => {
    const pairs = queryString.replace(/\?/, '').split('&');
    const result: any = {};
    pairs.forEach(function (pair: any) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
};

export const simpleEncrypt = (str: string) => {
    if (!str) {
        return '';
    }
    return btoa(str);
};

export const simpleDecrypt = (encryptStr: string | null | string[]) => {
    return typeof encryptStr === 'string' && isBase64Str(encryptStr) ? atob(encryptStr) : '';
};

export const isBase64Str = (value: string) => {
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(value);
};

export const scrollToRef = (ref: any) => {
    window.scrollTo(0, ref.current.offsetTop - 170);
};

export const scrollToRefNav = (ref: any) => {
    window.scrollTo(0, ref.current.offsetTop - 200);
};

export const customToString = (string: string | any) => {
    return string ? string.toString() : '';
};

export const convertToPlural = (count: number | any, word: string) => {
    if (count > 1) {
        return `${word}s`;
    }
    return word;
};

export const getURLWithQueryParams = (base: string, params: any) => {
    const query = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');

    return `${base}?${query}`;
};

/**
 * Get API Error Message
 * @param response
 */
export const getApiErrorMessage = (response: any) => {
    return response?.data?.error
        ? response?.data?.error
        : response?.message
        ? response?.message
        : 'Something went wrong. please try again';
};

/**
 * Amount Conversion
 * @param amount
 */
export const amountConvertToInd = (amount: number | any) => {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'INR',
    }).format(amount); // '€ 10,000.00'
};

// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Link } from 'react-router-dom';

const ServicesContent3 = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-4 col-md-6 cursor-pointer">
                    <Link to="/courses">
                        <div className="services-bx text-left m-b30">
                            <div className="feature-lg text-white m-b30">
                                <span className="icon-cell">
                                    <i className="flaticon-professor"></i>
                                </span>
                            </div>
                            <div className="icon-content">
                                <h4 style={{ fontSize: 20 }} className="ttr-tilte">
                                    Training & Development for Biomedical Engineers
                                </h4>
                                <p>
                                    Prashan medical technologies comitted to provide hands-on application & technical
                                    training to biomedical engineering ...
                                </p>
                                {/* <Link to="/about-us" className="readmore">
                                Learn More <i className="la la-arrow-right"></i>
                            </Link> */}
                            </div>
                            <div className="service-no">01</div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-6 cursor-pointer">
                    <Link to="/products">
                        <div className="services-bx text-left m-b30">
                            <div className="feature-lg text-white m-b30">
                                <span className="icon-cell">
                                    <i className="flaticon-exam"></i>
                                </span>
                            </div>
                            <div className="icon-content">
                                <h4 style={{ fontSize: 20 }} className="ttr-tilte">
                                    Medical Equipment Sales, Service & Rental
                                </h4>
                                <p>
                                    Prashan medical technologies is an authorised service provider for Schiller
                                    healthcare India Pvt Ltd. Our main focus is on critical ...
                                </p>
                                {/* <Link to="/about-us" className="readmore">
                                Learn More <i className="la la-arrow-right"></i>
                            </Link> */}
                            </div>
                            <div className="service-no">02</div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-12 cursor-pointer">
                    <Link to="#">
                        <div className="services-bx text-left">
                            <div className="feature-lg text-white m-b30">
                                <span className="icon-cell">
                                    <i className="flaticon-books"></i>
                                </span>
                            </div>
                            <div className="icon-content">
                                <h4 style={{ fontSize: 20 }} className="ttr-tilte">
                                    Research & <br /> Development{' '}
                                </h4>
                                <p>
                                    Prashan medical technologies committed to provide new products and methods to
                                    healthcare...
                                </p>
                                {/* <Link to="/about-us" className="readmore">
                                Learn More <i className="la la-arrow-right"></i>
                            </Link> */}
                            </div>
                            <div className="service-no">03</div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ServicesContent3;

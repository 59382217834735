import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import AppointmentBox from '../components/appointment-box';
import SingleEvent from '../components/singleEvent';
import Testimonial1 from '../components/testimonial1';
import PublicLayout from '../layout/PublicLayout';
import { singleEventsSaga } from '../reduxToolkit/actions';
import { useDispatch, useSelector } from 'react-redux';
import { storeTypes } from '../types';
import { InfoModal } from '../components/InfoModal';
import { ALERT_DANGER, ALERT_SUCCESS } from '../utils/constants';
import { studentRegistrationSlice } from 'reduxToolkit/reducers';

// Elements

// Images

const Registration = () => {
    const dispatch = useDispatch();

    const { sessionId } = useParams<{ sessionId: string }>();
    const [showEvent, setShowEvent] = useState(false);

    const { singleEvents, studentRegistration } = useSelector((state: storeTypes.IStore) => state);
    const { data: eventsData } = singleEvents;
    const { isSuccess, message, error } = studentRegistration;

    const { studentRegistrationReset } = studentRegistrationSlice.actions;

    useEffect(() => {
        if (sessionId) {
            dispatch(singleEventsSaga(parseInt(sessionId)));
            setShowEvent(true);
        }
    }, [sessionId]);

    const [showDialog, setShowDialog] = useState(false);
    const [showMsg, setShowMsg] = useState('');

    useEffect(() => {
        if (isSuccess === true && message) {
            setShowDialog(true);
            setShowMsg(message);
        } else if (isSuccess === false && error) {
            setShowDialog(true);
            setShowMsg(error);
        }
    }, [isSuccess]);
    return (
        <PublicLayout>
            {showDialog ? (
                <InfoModal
                    callBack={() => {
                        setShowDialog(false);
                        dispatch(studentRegistrationReset());
                        if (isSuccess === true) {
                            window.location.reload();
                        }
                    }}
                    modalClose={() => {
                        setShowDialog(false);
                        dispatch(studentRegistrationReset());
                        if (isSuccess === true) {
                            window.location.reload();
                        }
                    }}
                    isShowCancel={false}
                    type={isSuccess === true ? ALERT_SUCCESS : ALERT_DANGER}
                    message={showMsg}
                    confirmText={'OK'}
                />
            ) : null}
            <div className="page-content">
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Register Now</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    {showEvent && eventsData ? <SingleEvent eventsData={eventsData} /> : null}
                    <AppointmentBox sessionId={sessionId ? parseInt(sessionId) : null} />
                    <Testimonial1 />
                </div>
            </div>
        </PublicLayout>
    );
};

export default Registration;

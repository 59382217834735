import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { EVENTS_SAGA, SINGLE_EVENTS_SAGA } from 'reduxToolkit/actions';
import { commonSlice, eventsSlice, singleEventsSlice } from 'reduxToolkit/reducers';
import { eventsTypes } from 'types';
import { apiRoutes, apiCall, getApiErrorMessage } from 'utils/helpers';

const { eventsStart, eventsSuccess, eventsFailed } = eventsSlice.actions;
const { singleEventsStart, singleEventsSuccess, singleEventsFailed } = singleEventsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const eventsSaga = function* (eventPayload: PayloadAction<eventsTypes.IEventForm>): any {
    yield put(eventsStart(eventPayload.payload));
    try {
        const params = eventPayload.payload;
        const response = yield apiCall({ params, ...apiRoutes.events });
        if (response.status === 200) {
            yield put(eventsSuccess(response.data.data));
        } else {
            const errorData = {
                success: false,
                message: getApiErrorMessage(response),
            };
            yield put(eventsFailed(errorData));
        }
    } catch (error) {
        yield put(exceptionHandlerRequest(error));
    }
};

const singleEventsSaga = function* (eventPayload: PayloadAction<number>): any {
    yield put(singleEventsStart());
    try {
        const eventId = eventPayload.payload;

        const url = { ...apiRoutes.singleEvent };

        url.apiPath = url.apiPath.replace(':eventId', eventId ? eventId.toString() : '');

        const response = yield apiCall(url);
        if (response.status === 200) {
            yield put(singleEventsSuccess(response.data));
        } else {
            const errorData = {
                success: false,
                message: getApiErrorMessage(response),
            };
            yield put(singleEventsFailed(errorData));
        }
    } catch (error) {
        yield put(exceptionHandlerRequest(error));
    }
};

export const takeEventsSagaRequest = function* () {
    yield takeEvery(EVENTS_SAGA, eventsSaga);
};

export const takeSingleEventsSagaRequest = function* () {
    yield takeEvery(SINGLE_EVENTS_SAGA, singleEventsSaga);
};

import { all, fork } from 'redux-saga/effects';

import {
    takeTestimonialSagaRequest,
    takeEventsSagaRequest,
    takeSingleEventsSagaRequest,
    takeContactSagaRequest,
    takeStudentRegistrationSagaRequest,
} from './sagas';

const rootSaga = function* () {
    yield all([
        fork(takeTestimonialSagaRequest),
        fork(takeEventsSagaRequest),
        fork(takeSingleEventsSagaRequest),
        fork(takeContactSagaRequest),
        fork(takeStudentRegistrationSagaRequest),
    ]);
};

export { rootSaga };

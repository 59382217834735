// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// Images
// import coursesPic1 from '../assets/images/media/medical_device_image_3.jpg';
// import coursesPic3 from '../assets/images/media/medical_device_programming_language.jpg';

import EquipmentsHandsOnTraining from '../assets/images/media/medical_device_image_3.jpg';
import SensorsAndMeasurements from '../assets/images/media/sensor2.webp';
import ProductDesignAndDevelopment from '../assets/images/media/medical_device design_image_2.webp';
import EmbeddedSystems from '../assets/images/media/medical_device_programming_language.jpg';
import ImageProcessing from '../assets/images/media/image_processing.jpg';
import Virtual from '../assets/images/media/vitural.png';
import Printing3D from '../assets/images/media/3dprinting.png';
import Biomechanics from '../assets/images/media/biomechanics.jpg';

// Content
const content = [
    {
        thumb: EquipmentsHandsOnTraining,
        title: 'Equipments Hands-on Training',
        slug: 'equipments-hands-on-training',
        tag: 'Medical Equipments Hands-on Training',
        review: 3,
        priceDel: 120,
        price: 190,
    },
    {
        thumb: SensorsAndMeasurements,
        title: 'Sensors and Measurements',
        slug: 'sensors-and-measurements',
        tag: 'Medical Sensors and Measurements',
        review: 4,
        priceDel: 180,
        price: 150,
    },
    {
        thumb: ProductDesignAndDevelopment,
        title: 'Product Design and Development',
        slug: 'product-design-and-development',
        tag: 'Medical Product Design and Development',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: EmbeddedSystems,
        title: 'Embedded Systems',
        slug: 'embedded-systems',
        tag: 'Arduino, PIC, ARM',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: ImageProcessing,
        title: 'Image Processing',
        slug: 'image-processing',
        tag: 'Medical Image Processing',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Virtual,
        title: 'Virtual Instrumentation',
        slug: 'virtual-instrumentation',
        tag: 'LabVIEW',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Printing3D,
        title: '3D Printing',
        tag: 'Implants and Prosthetics',
        slug: 'implants-and-prosthetics',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Biomechanics,
        title: 'BioMechanics',
        slug: 'bio-mechanics',
        tag: 'Medical Technics and Bio Mechanics',
        review: 2,
        priceDel: 520,
        price: 234,
    },
];

const PopularCoursesSlider2 = () => {
    const settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                },
            },
            {
                breakpoint: 390,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <>
            <Slider {...settings} className="courses-carousel-2 slick-slider owl-none">
                {content.map((item, index) => (
                    <div key={`${index}A`} className="slider-item">
                        <div className="cours-bx">
                            <div className="action-box">
                                <img src={item.thumb} alt="" />
                                <Link to={`courses-details/${item?.slug}`} className="btn">
                                    Read More
                                </Link>
                            </div>
                            <div className="info-bx">
                                <h6>
                                    <Link to={`courses-details/${item?.slug}`}>{item.title}</Link>
                                </h6>
                                <span>{item.tag}</span>
                            </div>
                            {/* <div className="cours-more-info">
                                <div className="review">
                                    <span>3 Review</span>
                                    <ul className="cours-star">
                                        <li className="active">
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li className="active">
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li className="active">
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i className="fa fa-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price">
                                    <del>$190</del>
                                    <h5>$120</h5>
                                </div>
                            </div> */}
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    );
};

export default PopularCoursesSlider2;

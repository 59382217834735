// eslint-disable-next-line no-use-before-define
import React from 'react';

// Element
import PopularCoursesSlider3 from './popular-courses-slider3';

const FeatureContent3 = () => {
    return (
        <>
            <div className="section-area section-sp1">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 heading-bx style1 text-center">
                            <h2 className="title-head">Our Partners</h2>
                            {/* <p>
                                                It is a long established fact that a reader will be distracted by the
                                                readable content of a page
                                            </p> */}
                        </div>
                    </div>

                    <PopularCoursesSlider3 />
                    {/* <div className="text-center">
                                        <Link to="/courses" className="btn">
                                            View All Courses
                                        </Link>
                                    </div> */}
                </div>
            </div>
        </>
    );
};

export default FeatureContent3;

// eslint-disable-next-line no-use-before-define
import React from 'react';

import Slider from 'react-slick';

// Images
// import coursesPic1 from '../assets/images/media/medical_device_image_3.jpg';
// import coursesPic3 from '../assets/images/media/medical_device_programming_language.jpg';

import AICTE from '../assets/images/media/Partner/AICTE.jpg';
import Asian from '../assets/images/media/Partner/asian_college.png';
import Bannari from '../assets/images/media/Partner/Bannari_Amman_logo.png';
import Karpagam from '../assets/images/media/Partner/karpagam.png';
import Msme from '../assets/images/media/Partner/msme.webp';
import Nandha from '../assets/images/media/Partner/nandha_engineering.jpg';
import Paavai from '../assets/images/media/Partner/paavai_logo.png';
import Rathinam from '../assets/images/media/Partner/rathinam_technical_campus.png';
import Schiller from '../assets/images/media/Partner/schiller-logo.png';
import SNS from '../assets/images/media/Partner/SNS_college.png';
import SriSakthi from '../assets/images/media/Partner/Sri_shakthi_engineering.png';
import WomensPolu from '../assets/images/media/Partner/womens_polutechnics_college.webp';

// Content
const content = [
    {
        thumb: Schiller,
        title: 'BioMechanics',
        tag: 'Medical Technics and Bio Mechanics',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Bannari,
        title: 'Product Design and Development',
        tag: 'Medical Product Design and Development',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: SNS,
        title: 'BioMechanics',
        tag: 'Medical Technics and Bio Mechanics',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Karpagam,
        title: 'Embedded Systems',
        tag: 'Arduino, PIC, ARM',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Rathinam,
        title: 'BioMechanics',
        tag: 'Medical Technics and Bio Mechanics',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Nandha,
        title: 'Virtual Instrumentation',
        tag: 'LabVIEW',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Paavai,
        title: '3D Printing',
        tag: 'Implants and Prosthetics',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },

    {
        thumb: SriSakthi,
        title: 'BioMechanics',
        tag: 'Medical Technics and Bio Mechanics',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: WomensPolu,
        title: 'BioMechanics',
        tag: 'Medical Technics and Bio Mechanics',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
    {
        thumb: Asian,
        title: 'Sensors and Measurements',
        tag: 'Medical Sensors and Measurements',
        review: 4,
        priceDel: 180,
        price: 150,
    },
    {
        thumb: AICTE,
        title: 'Equipments Hands-on Training',
        tag: 'Medical Equipments Hands-on Training',
        review: 3,
        priceDel: 120,
        price: 190,
    },

    {
        thumb: Msme,
        title: 'Image Processing',
        tag: 'Medical Image Processing',
        // tag: 'Arduino, LabVIEW, Matlab, Comsol Multiphysics, Python, Multisim, Proteus',
        review: 2,
        priceDel: 520,
        price: 234,
    },
];

const PopularCoursesSlider3 = () => {
    const settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    dots: false,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 390,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <Slider {...settings} className="courses-carousel-2 slick-slider owl-none">
                {content.map((item, index) => (
                    <div key={`${index}A`} className="slider-item">
                        <div className="cours-bx">
                            <div className="action-box">
                                <img style={{ height: '175px', objectFit: 'contain' }} src={item.thumb} alt="" />
                            </div>

                            {/* <div className="cours-more-info">
                                <div className="review">
                                    <span>3 Review</span>
                                    <ul className="cours-star">
                                        <li className="active">
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li className="active">
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li className="active">
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i className="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i className="fa fa-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price">
                                    <del>$190</del>
                                    <h5>$120</h5>
                                </div>
                            </div> */}
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    );
};

export default PopularCoursesSlider3;

export const DEFAULT = 'default';
export const SUCCESS = 'success';
export const INFO = 'info';
export const WARNING = 'warning';
export const DANGER = 'danger';
export const CUSTOM = 'custom';

export const NOTIFY_TIMEOUT = 3000;

export const ALERT_SUCCESS = 'success';
export const ALERT_WARNING = 'warning';
export const ALERT_INFO = 'info';
export const ALERT_DANGER = 'danger';
export const ALERT_CUSTOM = 'custom';

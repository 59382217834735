import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

/**
 * Convert Date with custom format
 * @param date
 * @param format
 */
export const convertDate = (date: string | any, format: string) => {
    if (date && format) {
        return dayjs.utc(date).local().format(format);
    }
    return null;
};

/**
 * Convert Time
 * @param time
 * @param format
 */
export const convertTime = (time: string | any, format: string) => {
    if (time && format) {
        return dayjs(`2020-10-10 ${time}`).format(format);
    }
    return null;
};

/**
 * Get Current Age
 * @param date
 */
export const getAge = (date: string | any) => {
    return date ? Math.floor((new Date().getTime() - new Date(date).getTime()) / 3.154e10) : 0;
};

/**
 * Get Day Mode //Expired,Upcoming.Happening
 * @param date
 */
export const getDayMode = (date: string) => {
    const today = dayjs(new Date());
    const newDate = dayjs(date);
    let mode = '';
    /*if (newDate.isSame(today)) {
        mode = 'Happening';
    } else */ if (newDate.isAfter(today)) {
        mode = 'Upcoming';
    } else if (newDate.isBefore(today)) {
        mode = 'Expired';
    }
    return mode;
};

// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Link } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

// Images
import blogPic1 from '../assets/images/blog/recent-blog/pic1.jpg';
import blogPic2 from '../assets/images/blog/recent-blog/pic2.jpg';
import blogPic3 from '../assets/images/blog/recent-blog/pic3.jpg';
import galleryPic1 from '../assets/images/gallery/pic1.jpg';
import galleryPic2 from '../assets/images/gallery/pic2.jpg';
import galleryPic3 from '../assets/images/gallery/pic3.jpg';
import galleryPic4 from '../assets/images/gallery/pic4.jpg';
import galleryPic5 from '../assets/images/gallery/pic5.jpg';
import galleryPic7 from '../assets/images/gallery/pic7.jpg';
import galleryPic8 from '../assets/images/gallery/pic8.jpg';
import galleryPic9 from '../assets/images/gallery/pic9.jpg';

const content = [
    {
        thumb: galleryPic2,
    },
    {
        thumb: galleryPic1,
    },
    {
        thumb: galleryPic5,
    },
    {
        thumb: galleryPic7,
    },
    {
        thumb: galleryPic8,
    },
    {
        thumb: galleryPic9,
    },
    {
        thumb: galleryPic3,
    },
    {
        thumb: galleryPic4,
    },
];

const options = {
    settings: {
        overlayColor: 'rgba(0,0,0,0.9)',
        backgroundColor: '#FDC716',
        slideAnimationType: 'slide',
    },
    buttons: {
        backgroundColor: '#f7b205',
        iconColor: 'rgba(255, 255, 255, 1)',
        showDownloadButton: false,
        showAutoplayButton: false,
        showThumbnailsButton: false,
    },
    caption: {
        captionColor: '#232eff',
        captionFontFamily: 'Raleway, sans-serif',
        captionFontWeight: '300',
        captionTextTransform: 'uppercase',
    },
};

// eslint-disable-next-line func-style
function GalleryImg() {
    return (
        <>
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <ul className="magnific-image">
                        {content.map((item, index) => (
                            <li key={index}>
                                <img src={item.thumb} alt="" />
                            </li>
                        ))}
                    </ul>
                </SRLWrapper>
            </SimpleReactLightbox>
        </>
    );
}

const BlogAside = () => {
    return (
        <>
            <aside className="side-bar sticky-top">
                <div className="widget recent-posts-entry">
                    <h6 className="widget-title">Recent Posts</h6>
                    <div className="widget-post-bx">
                        <div className="widget-post clearfix">
                            <div className="ttr-post-media">
                                {' '}
                                <img src={blogPic1} width="200" height="143" alt="" />{' '}
                            </div>
                            <div className="ttr-post-info">
                                <div className="ttr-post-header">
                                    <h6 className="post-title">
                                        <Link to="/blog-details">
                                            This Story Behind Education Will Haunt You Forever.
                                        </Link>
                                    </h6>
                                </div>
                                <ul className="media-post">
                                    <li>
                                        <Link to="/blog-details">
                                            <i className="fa fa-calendar"></i>Oct 23 2021
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="widget-post clearfix">
                            <div className="ttr-post-media">
                                {' '}
                                <img src={blogPic2} width="200" height="160" alt="" />{' '}
                            </div>
                            <div className="ttr-post-info">
                                <div className="ttr-post-header">
                                    <h6 className="post-title">
                                        <Link to="/blog-details">
                                            What Will Education Be Like In The Next 50 Years?
                                        </Link>
                                    </h6>
                                </div>
                                <ul className="media-post">
                                    <li>
                                        <Link to="/blog-details">
                                            <i className="fa fa-calendar"></i>May 14 2021
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="widget-post clearfix">
                            <div className="ttr-post-media">
                                {' '}
                                <img src={blogPic3} width="200" height="160" alt="" />{' '}
                            </div>
                            <div className="ttr-post-info">
                                <div className="ttr-post-header">
                                    <h6 className="post-title">
                                        <Link to="/blog-details">Eliminate Your Fears And Doubts About Education.</Link>
                                    </h6>
                                </div>
                                <ul className="media-post">
                                    <li>
                                        <Link to="/blog-details">
                                            <i className="fa fa-calendar"></i>June 12 2021
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget widget-newslatter">
                    <h6 className="widget-title">Newsletter</h6>
                    <div className="news-box">
                        <p>Enter your e-mail and subscribe to our newsletter.</p>
                        <form className="subscription-form" method="post">
                            <div className="ajax-message"></div>
                            <div className="input-group">
                                <input
                                    name="email"
                                    required="required"
                                    type="email"
                                    className="form-control"
                                    placeholder="Your Email Address"
                                />
                                <button name="submit" value="Submit" type="submit" className="btn radius-no">
                                    <i className="fa fa-paper-plane-o"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="widget widget_gallery gallery-grid-4">
                    <h6 className="widget-title">Our Gallery</h6>
                    <GalleryImg />
                </div>
            </aside>
        </>
    );
};

export default BlogAside;

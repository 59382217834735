import * as HttpMethods from 'utils/constants/method';

export const apiRoutes = {
    register: { apiPath: '/api/register', action: HttpMethods.POST },
    testimonials: { apiPath: '/api/get_testimonials', action: HttpMethods.GET },
    getProducts: { apiPath: '/api/get_products', action: HttpMethods.GET },
    getCourse: { apiPath: '/api/get_course', action: HttpMethods.GET },
    events: { apiPath: '/api/get_events', action: HttpMethods.GET },
    singleEvent: { apiPath: '/api/get_event/:eventId', action: HttpMethods.GET },
    contact: { apiPath: '/api/contact_feedback', action: HttpMethods.POST },
    studentRegister: { apiPath: '/api/student_register_web', action: HttpMethods.POST },
    downloadLesson: { apiPath: '/api/download_lesson', action: HttpMethods.POST },
    getGallery: { apiPath: '/api/get_gallery', action: HttpMethods.GET },
    addNewsLetter: { apiPath: '/api/add-news-letter', action: HttpMethods.POST },
};

import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

// Layout

// Images
import bannerImg from '../assets/images/banner/banner2.jpg';
import PublicLayout from '../layout/PublicLayout';

import EquipmentsHandsOnTraining from '../assets/images/media/medical_device_image_3.jpg';
import SensorsAndMeasurements from '../assets/images/media/sensor2.webp';
import ProductDesignAndDevelopment from '../assets/images/media/medical_device design_image_2.webp';
import EmbeddedSystems from '../assets/images/media/medical_device_programming_language.jpg';
import ImageProcessing from '../assets/images/media/image_processing.jpg';
import Virtual from '../assets/images/media/vitural.png';
import Printing3D from '../assets/images/media/3dprinting.png';
import Biomechanics from '../assets/images/media/biomechanics.jpg';

const CoursesDetails = () => {
    const { courseSlug } = useParams<{ courseSlug: string }>();

    const content = [
        {
            thumb: EquipmentsHandsOnTraining,
            title: 'Equipments Hands-on Training',
            slug: 'equipments-hands-on-training',
            description:
                '<span style="color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px; text-align: justify; background-color: rgb(255, 255, 255);">It is a long <b>established </b>fact that a <i>reader will be distracted</i> by the readable <u>content of a page when looking</u> at its layout.&nbsp;</span><div><ul><li><span style="color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px; text-align: justify; background-color: rgb(255, 255, 255);">The point of using Lorem Ipsum</span></li><li><span style="color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px; text-align: justify; background-color: rgb(255, 255, 255);">&nbsp;is that it has a more-or-less normal&nbsp;</span></li><li><span style="color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px; text-align: justify; background-color: rgb(255, 255, 255);">distribution of letters, as opposed&nbsp;</span></li><li><span style="color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px; text-align: justify; background-color: rgb(255, 255, 255);">to using Content here, content here,&nbsp;</span></li><li><span style="color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px; text-align: justify; background-color: rgb(255, 255, 255);"><br></span></li></ul><div><span style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px; text-align: justify;">making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</span></div></div>',
            review: 3,
            priceDel: 120,
            price: 190,
        },
        {
            thumb: SensorsAndMeasurements,
            title: 'Sensors and Measurements',
            slug: 'sensors-and-measurements',
            description:
                '<div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);">Hi</span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);"><br></span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);">Hello</span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);"><br></span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);">How&nbsp;</span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);"><br></span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);">Are&nbsp;&nbsp;</span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);"><br></span></font></div><div style="text-align: justify;"><font color="#000000" face="Open Sans, Arial, sans-serif"><span style="font-size: 14px; background-color: rgb(255, 255, 255);">You</span></font></div>',
            review: 4,
            priceDel: 180,
            price: 150,
        },
        {
            thumb: ProductDesignAndDevelopment,
            title: 'Product Design and Development',
            slug: 'product-design-and-development',
            description: 'Medical Product Design and Development',
            review: 2,
            priceDel: 520,
            price: 234,
        },
        {
            thumb: EmbeddedSystems,
            title: 'Embedded Systems',
            slug: 'embedded-systems',
            description: 'Arduino, PIC, ARM',
            review: 2,
            priceDel: 520,
            price: 234,
        },
        {
            thumb: ImageProcessing,
            title: 'Image Processing',
            slug: 'image-processing',
            description: 'Medical Image Processing',
            review: 2,
            priceDel: 520,
            price: 234,
        },
        {
            thumb: Virtual,
            title: 'Virtual Instrumentation',
            slug: 'virtual-instrumentation',
            description: 'LabVIEW',
            review: 2,
            priceDel: 520,
            price: 234,
        },
        {
            thumb: Printing3D,
            title: '3D Printing',
            description: 'Implants and Prosthetics',
            slug: 'implants-and-prosthetics',
            review: 2,
            priceDel: 520,
            price: 234,
        },
        {
            thumb: Biomechanics,
            title: 'BioMechanics',
            slug: 'bio-mechanics',
            description: 'Medical Technics and Bio Mechanics',
            review: 2,
            priceDel: 520,
            price: 234,
        },
    ];

    const [course, setCourse] = useState<any>();

    useEffect(() => {
        if (courseSlug) {
            const getCourse = content.find((item) => item.slug === courseSlug);
            if (getCourse) {
                setCourse(getCourse);
            }
        }
    }, [courseSlug]);
    return (
        <PublicLayout>
            <div className="page-content">
                <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                    <div className="container">
                        <div className="page-banner-entry">
                            <h1 className="text-white">Courses Details</h1>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Courses Details</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    <div className="section-area section-sp1">
                        <div className="container">
                            <div className="row d-flex flex-row-reverse">
                                {/* <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 m-b30">
                                    <div className="course-detail-bx">
                                        <div className="course-buy-now text-center">
                                            <Link to="#" className="btn radius-xl">
                                                Instructor
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-xl-2"></div>
                                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                                    <div className="courses-post">
                                        <div className="ttr-post-media media-effect">
                                            <Link to="#">
                                                <img src={course?.thumb} alt="" />
                                            </Link>
                                        </div>
                                        <div className="ttr-post-info m-b30">
                                            <div className="ttr-post-title ">
                                                <h2 className="post-title">{course?.title}</h2>
                                            </div>
                                            <div
                                                className="ttr-post-text"
                                                dangerouslySetInnerHTML={{ __html: course?.description }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default CoursesDetails;

import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'block',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            textAlign: 'center',
            color: '#ffff',
        },
    })
);

export const CircleLoader = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress color="primary" size={40} />
        </div>
    );
};

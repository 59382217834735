export const validationMsg = {
    MIN: 8,
    MAX: 15,
    REQUIRED: 'This field is required',
    EMPTY: 'Password field is required',
    VALID_EMAIL: 'Enter a valid email',
    OLD_PASSWORD: 'Old password is required',
    NEW_PASSWORD: 'New password is required',
    CONFORM_PASSWORD: 'Confirm new password is required',
    MIN_VALIDATION: 'Password has to be longer than 8 characters',
    MAX_VALIDATION: 'Password has to be less than 15 characters',
    PASSWORD_MATCH_VALIDATE: 'Passwords must match',
    ACCEPT_TERMS: 'You must agree to terms and conditions and privacy policy.',
    PASSWORD_REG_EXP_MATCH: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
    PASSWORD_REGULAR_EXP_NOT_MATCH:
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special Character',
    PASSWORD_REG_EXP_NOT_MATCH:
        'Password length must be between 6 to 15 Characters, One Uppercase, One Lowercase, One Number and One special Character',
};

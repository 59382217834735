import { createSlice } from '@reduxjs/toolkit';
import { ICommon } from 'types/common/api';

const initialState: ICommon = {
    exception: {
        error: null,
    },
};

const commonSlice = createSlice({
    name: 'common',
    initialState: initialState,
    reducers: {
        exceptionHandlerRequest: (state, action) => {
            return {
                ...state,
                exception: {
                    error: action.payload,
                },
            };
        },
    },
});

export { commonSlice };

export const CONTACT_MOBILE_NUMBER = '918883774112';
export const CONTACT_EMAIL = '';
// export const CONTACT_EMAIL = 'support@prashanmedtech.com';
export const CONTACT_EMAIL2 = 'prashanmedtech@gmail.com';

export const FACEBOOK_LINK = 'https://www.facebook.com/Prashan-Medical-Technologies-106473944718934/';
export const TWITTER_LINK = 'https://twitter.com/medicalprashan/status/1412405848073293831?s=21';
export const LINKED_IN_LINK = 'https://in.linkedin.com/company/prashan-medical-technologies';
export const INSTAGRAM_LINK = 'https://www.instagram.com/prashan_medical_technologies/?hl=en';
export const TELEGRAM_LINK = 'https://t.me/med_tc';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UChp1rS2lEDTMvnQUiDLr05Q';

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

// Layout

// Elements

// Images
import EventPlaceHolder from '../assets/images/courses/pic10.jpg';
import bannerImg from '../assets/images/banner/banner1.jpg';
import ContactInfoCard from '../components/contact-info-card';
import PublicLayout from '../layout/PublicLayout';
import { singleEventsSaga } from '../reduxToolkit/actions';
import { storeTypes } from '../types';
import { amountConvertToInd, convertDate, convertTime, getDayMode } from '../utils/helpers';
import { config } from '../config';

const EventsDetails = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const dispatch = useDispatch();

    const [eventMode, setEventMode] = useState('');

    const { singleEvents } = useSelector((state: storeTypes.IStore) => state);
    const { data: eventsData } = singleEvents;
    const eventImage = eventsData?.file_path ? `${config.imgBaseURL}/${eventsData.file_path}` : null;

    useEffect(() => {
        if (eventId) {
            dispatch(singleEventsSaga(parseInt(eventId)));
        }
    }, [eventId]);

    useEffect(() => {
        if (eventsData?.start_date) {
            const dayMode = getDayMode(eventsData?.start_date || '');
            setEventMode(dayMode);
        }
    }, [eventsData]);
    return (
        <PublicLayout>
            <div className="page-content">
                <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                    <div className="container">
                        <div className="page-banner-entry">
                            <h1 className="text-white">Event Details</h1>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/events">Events</Link>
                            </li>
                            <li>Event Details</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    <div className="section-area section-sp1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-7 col-sm-12">
                                    <div className="courses-post">
                                        <div className="ttr-post-media media-effect">
                                            <img src={eventImage ? eventImage : EventPlaceHolder} alt="" />
                                        </div>
                                        <div className="ttr-post-info m-b30">
                                            <div className="ttr-post-title ">
                                                <h2 className="post-title">{eventsData?.title}</h2>
                                            </div>
                                            <div className="ttr-post-text">
                                                <p>{eventsData?.short_description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="courese-overview" id="overview">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-5">
                                                <ul className="course-features">
                                                    <li>
                                                        <i className="ti-book"></i> <span className="label">Type</span>{' '}
                                                        <span className="value">{eventsData?.type}</span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-calendar"></i>{' '}
                                                        <span className="label">Start date</span>{' '}
                                                        <span className="value">
                                                            {convertDate(eventsData?.start_date, 'MMM-DD-YYYY')}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-calendar"></i>{' '}
                                                        <span className="label">End Date</span>{' '}
                                                        <span className="value">
                                                            {convertDate(eventsData?.end_date, 'MMM-DD-YYYY')}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-timer"></i>{' '}
                                                        <span className="label">Start Time</span>{' '}
                                                        <span className="value">
                                                            {convertTime(eventsData?.start_time, 'hh:mm A')}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-timer"></i>{' '}
                                                        <span className="label">End Time</span>{' '}
                                                        <span className="value">
                                                            {convertTime(eventsData?.end_time, 'hh:mm A')}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-stats-up"></i>{' '}
                                                        <span className="label">Fees</span>{' '}
                                                        <span className="value">
                                                            {amountConvertToInd(parseInt(eventsData?.fees || ''))}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-stats-up"></i>{' '}
                                                        <span className="label">Offer Price</span>{' '}
                                                        <span className="value">
                                                            {amountConvertToInd(parseInt(eventsData?.offer || ''))}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-time"></i>{' '}
                                                        <span className="label">Duration</span>{' '}
                                                        <span className="value">{eventsData?.duration}</span>
                                                    </li>
                                                    <li>
                                                        <i className="ti-user"></i>{' '}
                                                        <span className="label">Who Can Attend</span>{' '}
                                                        <span className="value">{eventsData?.who_can_attend}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div
                                                className="col-md-12 col-lg-7"
                                                dangerouslySetInnerHTML={{
                                                    __html: eventsData?.description || '',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-5 col-sm-12 m-b30">
                                    <div className="sticky-top">
                                        <ContactInfoCard />
                                        <div className="map-frame2 m-t30">
                                            <iframe
                                                title={'1'}
                                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15664.704431706008!2d77.0203233!3d11.0254102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe907b8a2d9f0e2c1!2sPrashan%20Medical%20Technologies!5e0!3m2!1sen!2sin!4v1627117377695!5m2!1sen!2sin"
                                                width="600"
                                                height="450"
                                                allowFullScreen
                                                loading="lazy"
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                                {/*eventsData?.is_register &&*/}
                                {eventMode !== 'Expired' ? (
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <Link to={`/registration/${eventsData?.id}`} className="btn">
                                                Register Event
                                            </Link>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default EventsDetails;

import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import {
    CONTACT_MOBILE_NUMBER,
    CONTACT_EMAIL2,
    FACEBOOK_LINK,
    INSTAGRAM_LINK,
    LINKED_IN_LINK,
    TWITTER_LINK,
    TELEGRAM_LINK,
    YOUTUBE_LINK,
} from 'utils/constants';

import logo from '../../assets/files/logo_transparent.png';
// import adv from '../../assets/images/adv/adv.jpg';

const Header = () => {
    const [sideMenu, setSideMenu] = useState(false);

    return (
        <>
            <header className="header rs-nav header-transp arent">
                <div className="top-bar">
                    <div className="container">
                        <div className="row d-flex justify-content-between">
                            <div className="topbar-left">
                                <ul>
                                    <li>
                                        <a href={`tel:+${CONTACT_MOBILE_NUMBER}`}>
                                            <i className="fa fa-phone" />+{CONTACT_MOBILE_NUMBER}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://api.whatsapp.com/send?phone=${CONTACT_MOBILE_NUMBER}&amp;text=Hi there! I have a question :)`}
                                        >
                                            <i className="fa fa-whatsapp" />
                                            {CONTACT_MOBILE_NUMBER}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="topbar-right">
                                <ul>
                                    {/*<li>
                                        <select className="header-lang-bx">
                                            <option data-icon="flag flag-uk">English UK</option>
                                            <option data-icon="flag flag-us">English US</option>
                                        </select>
                                    </li>*/}
                                    <li>
                                        <a href={`mailto:${CONTACT_EMAIL2}`}>
                                            <i className="fa fa-envelope-o" /> {CONTACT_EMAIL2}
                                        </a>
                                    </li>
                                    {/*<li>
                                        <Link to="/login">Login</Link>
                                    </li>
                                    <li>
                                        <Link to="/register">Register</Link>
                                    </li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Sticky enabled={true} className="sticky-header navbar-expand-lg">
                    <div className="menu-bar clearfix">
                        <div className="container clearfix">
                            {/* <!-- Header Logo ==== --> */}
                            <div className="menu-logo">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                            {/* <!-- Mobile Nav Button ==== --> */}
                            <button
                                className={`navbar-toggler collapsed menuicon justify-content-end ${
                                    sideMenu ? 'open' : ''
                                }`}
                                onClick={() => {
                                    setSideMenu(!sideMenu);
                                }}
                                type="button"
                                data-toggle="collapse"
                                data-target="#menuDropdown"
                                aria-controls="menuDropdown"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span />
                                <span />
                                <span />
                            </button>
                            {/* <!-- Author Nav ==== --> */}
                            <div className="secondary-menu">
                                <div className="secondary-inner">
                                    <ul>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={FACEBOOK_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={TWITTER_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={INSTAGRAM_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={LINKED_IN_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-linkedin" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={TELEGRAM_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-telegram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={YOUTUBE_LINK}
                                                className="btn-link"
                                            >
                                                <i className="fa fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- Navigation Menu ==== --> */}
                            <div
                                className={`menu-links navbar-collapse collapse justify-content-start  ${
                                    sideMenu ? 'show' : ''
                                }`}
                                id="menuDropdown"
                            >
                                <div className="menu-logo">
                                    <Link to="/">
                                        <img src={logo} alt="" />
                                    </Link>
                                </div>
                                <ul className="nav navbar-nav">
                                    <li className="active">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="active">
                                        <Link to="/about-us">About Us</Link>
                                    </li>

                                    <li>
                                        <Link to="/events">Events</Link>
                                    </li>
                                    <li>
                                        <Link to="/products">Products</Link>
                                    </li>

                                    {/* <li className="add-mega-menu">
                                        <Link to="/courses">Courses</Link>
                                        <ul className="sub-menu add-menu">
                                            <li className="add-menu-left">
                                                <h5 className="menu-adv-title">Our Courses</h5>
                                                <ul>
                                                    <li>
                                                        <Link to="/courses">Courses </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/courses-details">Courses Details</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/profile">Instructor Profile</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/events">Upcoming Event</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="add-menu-right">
                                                <Link to="/registration">
                                                    <img src={adv} alt="" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </li> */}

                                    <li>
                                        <Link to="/portfolio">Portfolio</Link>
                                        {/* <ul className="sub-menu">
                                            <li>
                                                <Link to="/blog-classic-grid">Blog Classic</Link>
                                            </li>
                                            <li>
                                                <Link to="/blog-details">Blog Details</Link>
                                            </li>
                                        </ul>*/}
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                                <div className="nav-social-link">
                                    <a rel="noreferrer" target="_blank" href={FACEBOOK_LINK} className="btn-link">
                                        <i className="fa fa-facebook" />
                                    </a>
                                    <a rel="noreferrer" target="_blank" href={TWITTER_LINK} className="btn-link">
                                        <i className="fa fa-twitter" />
                                    </a>
                                    <a rel="noreferrer" target="_blank" href={INSTAGRAM_LINK} className="btn-link">
                                        <i className="fa fa-instagram" />
                                    </a>
                                    <a rel="noreferrer" target="_blank" href={LINKED_IN_LINK} className="btn-link">
                                        <i className="fa fa-linkedin" />
                                    </a>
                                    <a rel="noreferrer" target="_blank" href={TELEGRAM_LINK} className="btn-link">
                                        <i className="fa fa-telegram" />
                                    </a>
                                    <a rel="noreferrer" target="_blank" href={YOUTUBE_LINK} className="btn-link">
                                        <i className="fa fa-youtube" />
                                    </a>
                                </div>
                            </div>
                            {/* <!-- Navigation Menu END ==== --> */}
                        </div>
                    </div>
                </Sticky>
            </header>
        </>
    );
};
export default Header;

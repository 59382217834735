import React from 'react';

import { Router as ReactRouter, Route, Switch } from 'react-router-dom';

// Elements
// eslint-disable-next-line import/order
import BackToTop from '../components/back-top';
// eslint-disable-next-line import/order
import PageScrollTop from '../components/page-scroll-top';

// Home Pages

// About Us
// eslint-disable-next-line import/order
import AboutUs from '../pages/about-us';

// Events

// Faq

// Other Pages

// Courses

// Blog Pages
import Blog from '../pages/blog';
import BlogDetails from '../pages/blog-details';
// Contact Us
import ContactUs from '../pages/contactUs';
import Courses from '../pages/courses';
import CoursesDetails from '../pages/courses-details';
import Error404 from '../pages/error-404';
import Events from '../pages/events';
import EventsDetails from '../pages/events-details';
import ForgetPassword from '../pages/forget-password';
import Index from '../pages/index';
import Login from '../pages/login';
import Portfolio from '../pages/portfolio';
import Profile from '../pages/profile';
import Register from '../pages/register';
import Registration from '../pages/registration';
import { createBrowserHistory } from 'history';
import Products from 'pages/products';
import DownloadLesson from 'pages/downloadLesson';

const history = createBrowserHistory();
const Markup = () => {
    return (
        <>
            <ReactRouter history={history}>
                <Switch>
                    {/* Wanted Routes */}
                    <Route path="/" exact component={Index} />
                    <Route path="/home" exact component={Index} />
                    <Route path="/index" exact component={Index} />

                    {/* About Us */}
                    <Route path="/about-us" exact component={AboutUs} />

                    {/* Events */}
                    <Route path="/events" exact component={Events} />
                    <Route path="/events-details/:eventId" exact component={EventsDetails} />

                    {/* Courses */}
                    <Route path="/courses" exact component={Courses} />
                    <Route path="/products" exact component={Products} />
                    <Route path="/courses-details/:courseSlug" exact component={CoursesDetails} />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/portfolio" exact component={Portfolio} />
                    <Route path="/registration/:sessionId?" exact component={Registration} />
                    <Route path="/download-lesson" exact component={DownloadLesson} />

                    {/* Blog Pages */}
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog-details" exact component={BlogDetails} />

                    {/* Contact Us */}
                    <Route path="/contact-us" exact component={ContactUs} />

                    {/* Other Pages */}
                    <Route path="/portfolio" exact component={Portfolio} />

                    <Route path="/register" exact component={Register} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/forget-password" exact component={ForgetPassword} />

                    {/* UnWanted Routes */}

                    {/* About Us */}
                    <Route path="/about-1" exact component={AboutUs} />

                    <Route path="*" exact component={Error404} />
                </Switch>

                <PageScrollTop />
            </ReactRouter>

            <BackToTop />
        </>
    );
};

export default Markup;

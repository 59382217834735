// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Images
import bg4 from '../assets/images/background/bg4.jpg';
import EventPlaceHolder from '../assets/images/courses/pic10.jpg';
import { eventsSaga } from '../reduxToolkit/actions';
import { storeTypes, eventsTypes } from '../types';
import { convertDate, convertTime } from '../utils/helpers';
import { config } from '../config';

const EventsContent2 = () => {
    const dispatch = useDispatch();

    const { events } = useSelector((state: storeTypes.IStore) => state);
    const { data: eventsData } = events;

    useEffect(() => {
        const payload = {
            is_upcoming: true,
            limit: 3,
        };
        dispatch(eventsSaga(payload));
    }, []);

    return (
        <React.Fragment>
            <div
                className="section-area section-sp2"
                style={{ backgroundImage: `url(${bg4})`, backgroundSize: 'cover' }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 style1 text-center heading-bx">
                            <h2 className="title-head m-b0">Upcoming Events</h2>
                            {/* <p className="m-b0">
                                Lorem Ipsum has been the industry's standard dummy text ever since when an unknown
                                printer took a galley.
                            </p> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="event-bx style1">
                                <div className="action-box">
                                    <img src={EventPlaceHolder} alt="" />
                                </div>
                                <div className="info-bx d-flex">
                                    <div className="event-info">
                                        <ul className="media-post">
                                            <li>
                                                <i className="fa fa-calendar-o"></i>{' '}
                                                {convertDate('2021-09-20', 'MMM-DD-YYYY')}
                                                {/* {' to '}
                                                {convertDate('2000-10-10', 'MMM-DD-YYYY')} */}
                                            </li>
                                            <li>
                                                <i className="fa fa-clock-o"></i> {convertTime('17:00', 'hh:mm A')}
                                                {' to '}
                                                {convertTime('19:00', 'hh:mm A')}
                                            </li>
                                            {/* <li>
                                                <i className="fa fa-user-o"></i> Who Attend
                                            </li> */}
                                            {/*<li>
                                                    <Link to="/events-details">
                                                        <i className="fa fa-map-marker"></i> Berlin, Germany
                                                    </Link>
                                                </li>*/}
                                        </ul>
                                        <h4 className="event-title">
                                            {
                                                '2weeks(Evening Batch) Online Medical Equipment Internship and Training on Critical care Equipment’s & Ventilator_ Batch VIII'
                                            }
                                        </h4>
                                        <p>
                                            Duration: 2weeks(14days) <br /> Training Dates: Batch-VIII starts from
                                            20-September-2021 <br /> Training Time: 5:00PM- 7:00PM (The flexible timing
                                            can be discussed on completion of registration) <br /> Mode of Training :
                                            Online (Google meet) <br /> Training Fee: Rs. 3000/-Incl GST (For Individual
                                            Registration) <br /> Training content : Refer Brochure <br /> Contact us at
                                            +918883774112 or prashanmedtech@gmail.com for further queries <br /> <br />{' '}
                                            Note: All your registered information will be kept confidentially. <br />
                                            <br />
                                            <div>
                                                <a
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href="https://docs.google.com/forms/d/e/1FAIpQLSe3vZPG70bHljCT_33tceiVw0SKhHfFg5Ty16mE7DE0BnEnzg/viewform"
                                                    className="btn"
                                                >
                                                    Register
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div className="event-time">
                                    <div className="event-date">{convertDate('2021-09-20', 'DD')}</div>
                                    <div className="event-month">{convertDate('2021-09-20', 'MMMM')}</div>
                                    <div className="event-month">{'Online'}</div>
                                </div>
                            </div>
                        </div>

                        {eventsData.map((item: eventsTypes.IEventData, index: number) => {
                            const eventImage = item.file_path ? `${config.imgBaseURL}/${item.file_path}` : null;
                            return (
                                <div key={index} className="col-md-12">
                                    <div className="event-bx style1">
                                        <div className="action-box">
                                            <img
                                                src={eventImage || EventPlaceHolder}
                                                alt=""
                                                onError={(e: any) => {
                                                    e.target.src = EventPlaceHolder;
                                                }}
                                            />
                                        </div>
                                        <div className="info-bx d-flex">
                                            <div className="event-info">
                                                <ul className="media-post">
                                                    <li>
                                                        <Link to={`/events-details/${item?.id}`}>
                                                            <i className="fa fa-calendar-o"></i>{' '}
                                                            {convertDate(item?.start_date, 'MMM-DD-YYYY')}
                                                            {' to '}
                                                            {convertDate(item?.end_date, 'MMM-DD-YYYY')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={`/events-details/${item?.id}`}>
                                                            <i className="fa fa-clock-o"></i>{' '}
                                                            {convertTime(item?.start_time, 'hh:mm A')}{' '}
                                                            {convertTime(item?.end_time, 'hh:mm A')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={`/events-details/${item?.id}`}>
                                                            <i className="fa fa-user-o"></i> {item?.who_can_attend}
                                                        </Link>
                                                    </li>
                                                    {/*<li>
                                                    <Link to={`/events-details/${item?.id}`}>
                                                        <i className="fa fa-map-marker"></i> Berlin, Germany
                                                    </Link>
                                                </li>*/}
                                                </ul>
                                                <h4 className="event-title">
                                                    <Link to={`/events-details/${item?.id}`}>{item?.title}</Link>
                                                </h4>
                                                <p>{item?.short_description}</p>
                                            </div>
                                        </div>
                                        <div className="event-time">
                                            <div className="event-date">{convertDate(item?.start_date, 'DD')}</div>
                                            <div className="event-month">{convertDate(item?.start_date, 'MMMM')}</div>
                                            <div className="event-month">{item?.type}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* <div className="text-center">
                        <Link to="/events" className="btn">
                            View All Events
                        </Link>
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default EventsContent2;

import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import bg1 from '../assets/images/background/bg1.jpg';
import PublicLayout from '../layout/PublicLayout';
import appBg from '../assets/images/background/appointment-bg.png';
import { apiCall, apiRoutes } from 'utils/helpers';
import { FullPageLoader } from 'components/shared/loader/FullPageLoader';

const DownloadLesson = () => {
    const [email, setEmail] = useState('');
    const [eventId, setEventId] = useState('');
    const [validation, setValidation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const dispathcAPI = async () => {
        if (!email || !eventId) {
            setValidation(true);
            return;
        }
        setValidation(false);
        const data = {
            email,
            reg_number: eventId,
        };
        setIsLoading(true);
        const response = await apiCall({ ...apiRoutes.downloadLesson, data });
        if (response?.data?.status) {
            setErrorMsg('');
            setEventId('');
            setEmail('');
            setIsLoading(false);
            window.open(
                'http://localhost:3000/lesson/lesson.pdf',
                'liveMatches',
                'width=720,height=800,toolbar=0,location=0, directories=0, status=0, menubar=0'
            );
        } else {
            setIsLoading(false);
            setErrorMsg(response?.data?.error);
        }
    };

    return (
        <PublicLayout>
            <FullPageLoader isLoad={isLoading} />
            <div className="page-content">
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Download Lesson</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    <>
                        <div
                            className="section-area section-sp3 ovpr-dark bg-fix appointment-box"
                            style={{ backgroundImage: `url(${bg1})` }}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 heading-bx style1 text-white text-center">
                                        <h2 className="title-head">Register Now</h2>
                                        {/* <p>
                                    Lorem Ipsum has been the industry's standard dummy text ever since when an unknown
                                    printer took a galley.
                                </p>*/}
                                    </div>
                                </div>

                                <form method="post" className="contact-bx ajax-form">
                                    <div className="ajax-message">
                                        <small className="error__txt"> {errorMsg}</small>
                                    </div>

                                    <div className="row placeani">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                        className="form-control valid-character"
                                                    />
                                                    {!email && validation && (
                                                        <small className="error__txt">This field is required</small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input
                                                        value={eventId}
                                                        onChange={(e) => setEventId(e.target.value)}
                                                        name="Event Registration Id"
                                                        type="Event Registration Id"
                                                        placeholder="Event Registration Id"
                                                        className="form-control"
                                                    />
                                                    {!eventId && validation && (
                                                        <small className="error__txt">This field is required</small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button
                                                onClick={() => dispathcAPI()}
                                                name="submit"
                                                type="button"
                                                value="Submit"
                                                className="btn button-md"
                                            >
                                                Register
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <img src={appBg} className="appoint-bg" alt="" />
                        </div>
                    </>
                </div>
            </div>
        </PublicLayout>
    );
};

export default DownloadLesson;

import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { CONTACTS_SAGA } from 'reduxToolkit/actions';
import { contactsSlice } from 'reduxToolkit/reducers';
import { contactTypes } from 'types';
import { apiRoutes, apiCall, getApiErrorMessage } from 'utils/helpers';

const { contactsStart, contactsSuccess, contactsFailed } = contactsSlice.actions;

const contactsSaga = function* (payloadData: PayloadAction<contactTypes.IContactForm>): any {
    yield put(contactsStart(payloadData.payload));
    const { name, email, contactMessage, subject, phoneNumber } = payloadData.payload;
    const data = {
        name,
        email,
        message: contactMessage,
        subject,
        phoneNumber,
    };
    const response = yield apiCall({ data, ...apiRoutes.contact });

    if (response.status === 200 && response.data.success) {
        yield put(contactsSuccess(response.data));
    } else {
        const errorData = {
            success: false,
            error: getApiErrorMessage(response),
        };
        console.log(errorData);
        console.log(response.data);
        yield put(contactsFailed(response.data));
    }
};

export const takeContactSagaRequest = function* () {
    yield takeEvery(CONTACTS_SAGA, contactsSaga);
};

import React from 'react';

// eslint-disable-next-line import/order
import ReactDOM from 'react-dom';

import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { persistor, store } from './reduxToolkit';
import reportWebVitals from './reportWebVitals';
import { FullPageLoader } from './components/shared';
import GA4React from 'ga-4-react';
import { BrowserRouter } from 'react-router-dom';
// ReactDOM.render(<App />, document.getElementById('root'));
const ga4react = new GA4React('G-7B0YEHLR06');

ga4react.initialize();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <FullPageLoader />
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Backdrop, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { storeTypes } from 'types';

const useStyles = makeStyles(() =>
    createStyles({
        backdrop: {
            zIndex: 1350,
            color: '#fff',
        },
    })
);

export const FullPageLoader = React.memo(({ isLoad }: { isLoad?: boolean }) => {
    const classes = useStyles();
    const loadingState = useSelector((state: storeTypes.IStore) => state);

    const isLoading =
        loadingState.events.isLoading ||
        loadingState.contacts.isLoading ||
        loadingState.singleEvents.isLoading ||
        loadingState.studentRegistration.isLoading ||
        isLoad;
    return (
        <div>
            <Backdrop className={classes.backdrop} open={isLoading ? isLoading : false}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonApiTypes, eventsTypes } from 'types';

const initialState: eventsTypes.ISingleEvent = {
    isLoading: false,
    message: '',
    errors: [],
    error: '',
    data: null,
};

const singleEventsSlice = createSlice({
    name: 'singleEvents',
    initialState: initialState,
    reducers: {
        singleEventsReset: () => {
            return {
                ...initialState,
            };
        },
        singleEventsStart: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        singleEventsSuccess: (state, action: PayloadAction<eventsTypes.ISingleEventResponse>) => {
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                isSuccess: true,
                errors: [],
                error: '',
            };
        },
        singleEventsFailed: (state, action: PayloadAction<commonApiTypes.IErrorData>) => {
            return {
                ...state,
                errors: action.payload?.errors || [],
                error: action.payload.error,
                message: '',
                isLoading: false,
                isSuccess: false,
            };
        },
    },
});

export { singleEventsSlice };

// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

// Images
import ExcellentProducts from '../assets/images/media/ExcellentProduct.jpg';
import InstantaneousSupport from '../assets/images/media/medical_device_image_4.jpg';
import BestIndustryExperts from '../assets/images/media/video_cover.JPG';
import FriendlyEnv from '../assets/images/media/FriendlyEnv.jpg';

class ServicesContent1 extends Component {
    render() {
        const data = [
            {
                link: '/products',
                image: ExcellentProducts,
                title: 'Excellent Products',
                description:
                    'Prashan medical technologies committed to provide excellent products and instatanous support to our customers',
            },
            {
                link: '/products',
                image: InstantaneousSupport,
                title: 'Instantaneous support',
                description:
                    'Prashan medical technologies committed to provide excellent products and instatanous support to our customers',
            },
            {
                link: '/courses',
                image: BestIndustryExperts,
                title: 'Best Industry Experts',
                description:
                    'We are having team of industry experts from various domains in medical industry to share the knowledge to the students.',
            },
            {
                link: '/portfolio',
                image: FriendlyEnv,
                title: 'Friendly Environment',
                description:
                    'We are committed to provide friendly environment to our customers to satisfy their expectation in most proper way.',
            },
        ];

        return (
            <>
                <div className="section-area bg-gray section-sp1 choose-bx">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 heading-bx left">
                                <h2 className="title-head">
                                    Why Choose <span> Our Organization</span>
                                </h2>
                                {/* <p>
                                    It is a long established fact that a reader will be distracted by the readable
                                    content of a page
                                </p> */}
                            </div>
                        </div>
                        <div className="row">
                            {data.map((item, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="service-bx m-b30">
                                            <div className="action-box">
                                                <img src={item?.image} alt="" />
                                            </div>
                                            <div className="info-bx">
                                                <h4>
                                                    <Link to="/about-us">{item?.title}</Link>
                                                </h4>
                                                <p>{item?.description}</p>
                                                <Link to={item?.link} className="btn radius-xl">
                                                    View More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ServicesContent1;

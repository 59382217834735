import React, { Component } from 'react';

// Layout
// import { Link } from 'react-router-dom';

import bg4 from '../assets/images/background/bg4.jpg';
// import AppointmentBox from '../components/appointment-box';
import Counter from '../components/counter/counter';
import EventsContent2 from '../components/events-content2';
import FeatureContent3 from '../components/feature-content3';
import JoinBx from '../components/join-bx';
import OurStory2 from '../components/our-story/our-story2';
// import PopularCoursesSlider from '../components/popular-courses-slider';
import PopularCoursesSlider2 from '../components/popular-courses-slider2';
import ServicesContent3 from '../components/services-content-3';
import MainSlider from '../components/slider/slider2';
import Testimonial3 from '../components/testimonial3';
import PublicLayout from '../layout/PublicLayout';

// Elements

// Images

class Index3 extends Component {
    render() {
        return (
            <PublicLayout>
                <div className="page-content bg-white">
                    <MainSlider />

                    <div className="content-block" id="content-area">
                        <div
                            className="popular-courses-bx"
                            style={{ backgroundImage: `url(${bg4})`, backgroundSize: 'cover' }}
                        >
                            <div className="section-area section-sp3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 heading-bx style1 text-center">
                                            <h2 className="title-head">Our Awesome Services</h2>
                                            {/* <p>
                                                It is a long established fact that a reader will be distracted by the
                                                readable content of a page
                                            </p> */}
                                        </div>
                                    </div>

                                    <ServicesContent3 />
                                </div>
                            </div>

                            <div className="section-area section-sp1">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12 heading-bx style1 text-center">
                                            <h2 className="title-head">Popular Courses</h2>
                                            {/* <p>
                                                It is a long established fact that a reader will be distracted by the
                                                readable content of a page
                                            </p> */}
                                        </div>
                                    </div>

                                    <PopularCoursesSlider2 />
                                    {/* <div className="text-center">
                                        <Link to="/courses" className="btn">
                                            View All Courses
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        {/*<AppointmentBox />*/}

                        {/*<PopularCoursesSlider />*/}

                        <JoinBx />

                        <FeatureContent3 />

                        <Counter />

                        <EventsContent2 />

                        <OurStory2 />

                        <Testimonial3 />
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default Index3;

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';

import { config } from '../config';
import { testimonialsSaga } from '../reduxToolkit/actions';
import { storeTypes, testimonialTypes } from '../types';

const Testimonial1 = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const dispatch = useDispatch();

    const { testimonials } = useSelector((state: storeTypes.IStore) => state);
    const { data: testimonialData } = testimonials;

    useEffect(() => {
        dispatch(testimonialsSaga());
    }, []);

    return (
        <>
            <div className="section-area section-sp2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 heading-bx left">
                            <h2 className="title-head">
                                What People <span>Say</span>
                            </h2>
                            <p>
                                It is a long established fact that a reader will be distracted by the readable content
                                of a page
                            </p>
                        </div>
                    </div>

                    <Slider {...settings} className="testimonial-carousel slick-slider owl-btn-1">
                        {testimonialData.map((item: testimonialTypes.ITestimonialData, index) => {
                            const imgBaseURL = config.imgBaseURL;
                            const fileURL = item?.file_path;
                            return (
                                <div key={index} className="slider-item">
                                    <div className="testimonial-bx">
                                        <div className="testimonial-head">
                                            {item?.file_path ? (
                                                <div className="testimonial-thumb">
                                                    <img src={`${imgBaseURL}/${fileURL}`} alt="" />
                                                </div>
                                            ) : null}
                                            <div className="testimonial-info">
                                                <h5 className="name">{item?.name}</h5>
                                                <p>-{item?.position}</p>
                                            </div>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>{item?.content}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Testimonial1;

// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

// Images
import VideoCover from '../../assets/images/media/video_cover.JPG';

class OurStory1 extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true });
    }

    render() {
        return (
            <>
                <div className="section-area bg-gray section-sp1 our-story pt-0">
                    <div className="container">
                        <div className="row align-items-center d-flex">
                            <div className="col-lg-5 col-md-12 m-b30">
                                <div className="heading-bx left mb-3">
                                    <h2 className="title-head">
                                        Our <span>Story</span>
                                    </h2>
                                    {/* <p>
                                        It is a long established fact that a reader will be distracted by the readable
                                        content of a page
                                    </p> */}
                                </div>
                                <p className="m-b30">
                                    Prashan Medical Technologies is Medical Equipment Supplier, Training and development
                                    company based at coimbatore. Prashan Medical Technologies was founded in 2017 by Mr.
                                    Boopathi Sakthivel. Our founder is having almost 10years of experience in the
                                    medical industry. He has worked in India, UAE, Germany, etc.,Prashan Medical
                                    Technologies is the Authorized Service Provider for Schiller Healthcare India Pvt
                                    Ltd and sole proprietorship healthcare company in the India and ranks amongst the
                                    leading medical equipment supplier,medical equipment service & hands-on experience
                                    providers in the Tamilnadu. Over the last 5 years, Prashan Medical Technologies has
                                    earned the trust of millions, first class equipment & service,thanks to its
                                    personalized skill development, genuine concern and a sincere commitment to the
                                    overall well-being of the society.
                                </p>
                            </div>

                            <div className="col-lg-7 col-md-12 heading-bx p-lr">
                                <div className="video-bx">
                                    <img src={VideoCover} alt="" />
                                    <Link onClick={this.openModal} to="#" className="popup-youtube video">
                                        <i className="fa fa-play"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <p>
                                    Beginning with a four-room flat as a one-man business, the company has become a
                                    successful group. Today, Prashan Medical Technologies is a leading medical equipment
                                    supplier and service provider for critical care, home care, cardiopulmonary
                                    diagnostics, defibrillation, patient monitoring and hands-on training provider to
                                    the biomedical engineering students as well as career guidance solutions for the
                                    medical industry. The company has put the key components in place to create a
                                    strong, skilled, inter-connected, integrated multi-vertical and multi-brand private
                                    healthcare service provider network with the scalability and flexibility to grow our
                                    operations in the future.
                                </p>

                                <p>
                                    For more than four years, Prashan Medical Technologies has been committed to the
                                    fight against the gap between theoretical studies and hands-on experience. While the
                                    most established biomedical skill development centers still provides trainings on
                                    small scale devices, Prashan Medical Technologies launched a system to provide
                                    training on large scale medical equipments in online & offline. Simultaneously, the
                                    company not only made a name for itself, but also set new standards. The next major
                                    step was achieved in 2021, through the alliance with various engineering colleges, a
                                    company specialized in the field of career guidance along with the skill
                                    development.
                                </p>

                                <p>
                                    In spite of the company's constant progress, size is not as important to PRASHAN
                                    MEDICAL TECHNOLOGIES as is strength & friendly environment. It relies on a motivated
                                    team, creativity, openness to new ideas and pioneer courage. "Let’s learn together"
                                    is extremely creative and it inspires us to continuously improve our service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="qf4pegdkOvU"
                    onClose={() => this.setState({ isOpen: false })}
                />
            </>
        );
    }
}

export default OurStory1;

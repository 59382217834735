import React from 'react';

import Footer from 'layout/footer';
import Header from 'layout/header';

const PublicLayout = ({ children }: any) => {
    return (
        <React.Fragment>
            <Header />
            <main>{children}</main>
            <footer>
                <Footer />
            </footer>
        </React.Fragment>
    );
};

export default PublicLayout;

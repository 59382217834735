import { createSlice } from '@reduxjs/toolkit';
import { modalTypes } from 'types';

const initialState: modalTypes.IModal = {
    isModalForLoginRegister: false,
    isModalForSocialLogin: false,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        isModalForLoginRegister: (state, action) => {
            return {
                ...state,
                isModalForLoginRegister: action.payload,
            };
        },
        isModalForSocialLogin: (state, action) => {
            return {
                ...state,
                isModalForSocialLogin: action.payload,
            };
        },
    },
});

export const { isModalForLoginRegister } = modalSlice.actions;

export { modalSlice };

import React from 'react';

import { Link } from 'react-router-dom';
import { eventsTypes } from 'types';
import bg4 from '../assets/images/background/bg4.jpg';
import EventImg10 from '../assets/images/courses/pic10.jpg';
import { convertDate, convertTime } from '../utils/helpers';

const SingleEvent = ({ eventsData }: { eventsData: eventsTypes.IEventData }) => {
    return (
        <React.Fragment>
            <div
                className="section-area section-sp2"
                style={{ backgroundImage: `url(${bg4})`, backgroundSize: 'cover' }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 style1 text-center heading-bx">
                            <h2 className="title-head m-b0">Event Registration</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="event-bx style1">
                                <div className="action-box">
                                    <img src={EventImg10} alt="" />
                                </div>
                                <div className="info-bx d-flex">
                                    <div className="event-info">
                                        <ul className="media-post">
                                            <li>
                                                <i className="fa fa-calendar-o"></i>{' '}
                                                {convertDate(eventsData?.start_date, 'MMM-DD-YYYY')}
                                                {' to '}
                                                {convertDate(eventsData?.end_date, 'MMM-DD-YYYY')}
                                            </li>
                                            <li>
                                                <i className="fa fa-clock-o"></i>{' '}
                                                {convertTime(eventsData?.start_time, 'hh:mm A')}{' '}
                                                {convertTime(eventsData?.end_time, 'hh:mm A')}
                                            </li>
                                            <li>
                                                <i className="fa fa-user-o"></i> {eventsData?.who_can_attend}
                                            </li>
                                        </ul>
                                        <h4 className="event-title">
                                            <Link to={`/events-details/${eventsData?.id}`}>{eventsData?.title}</Link>
                                        </h4>
                                        <p>{eventsData?.short_description}</p>
                                    </div>
                                </div>
                                <div className="event-time">
                                    <div className="event-date">{convertDate(eventsData?.start_date, 'DD')}</div>
                                    <div className="event-month">{convertDate(eventsData?.start_date, 'MMMM')}</div>
                                    <div className="event-month">{eventsData?.type}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SingleEvent;

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import bannerImg from '../assets/images/banner/banner2.jpg';
import EventsContent from '../components/events-content';
import PublicLayout from '../layout/PublicLayout';
import { eventsSaga } from '../reduxToolkit/actions';

const Events = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const payload = {
            is_upcoming: false,
            limit: null,
        };
        dispatch(eventsSaga(payload));
    }, []);
    return (
        <PublicLayout>
            <div className="page-content">
                <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                    <div className="container">
                        <div className="page-banner-entry">
                            <h1 className="text-white">Events</h1>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-row">
                    <div className="container">
                        <ul className="list-inline">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Events</li>
                        </ul>
                    </div>
                </div>

                <div className="content-block">
                    <div className="section-area section-sp1 gallery-bx">
                        <div className="container">
                            <EventsContent />
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Events;
